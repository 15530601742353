import { AxiosInstance } from 'axios'
import { useQuery } from '@tanstack/react-query'
import type { UserMeRead } from 'types/api'

export const authenticatedUserQueryKey = ['me']

export function useAuthenticatedUser(apiClient: AxiosInstance) {
  async function getAuthenticatedUser() {
    const response = await apiClient.get<UserMeRead>('v3/users/me')
    return response.data
  }

  return useQuery({
    queryFn: getAuthenticatedUser,
    queryKey: authenticatedUserQueryKey,
  })
}
