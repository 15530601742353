import { AxiosInstance } from 'axios'
import { useQuery } from '@tanstack/react-query'
import type { SaivaProduct, UserMeOrgsRead } from 'types/api'

type UseUserAccessOrgs = {
  apiClient: AxiosInstance
  product?: SaivaProduct
  isLoading: boolean
}

export function useUserAccessOrgs(args: UseUserAccessOrgs) {
  const { apiClient, product, isLoading } = args

  async function getUserAccessOrgs() {
    const response = await apiClient.get<UserMeOrgsRead>(
      `v3/users/me/${product}`
    )

    return response.data.orgs
  }

  return useQuery({
    queryFn: getUserAccessOrgs,
    queryKey: ['me', product],
    enabled: !!product,
    retry: isLoading
  })
}
