/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Action */
export enum Action {
  ScreenNotComplete = 'screen_not_complete',
  ScreenCompletePendingPhysicianOrder = 'screen_complete_pending_physician_order',
  OrderReceivedPendingEvaluation = 'order_received_pending_evaluation',
  EvaluationComplete = 'evaluation_complete',
  EvaluationNotIndicated = 'evaluation_not_indicated',
  AlreadyOnCaseload = 'already_on_caseload'
}

/** ActionItem */
export interface ActionItem {
  action: Action
  reason?: FailedReason | null
  /** Rescreen At */
  rescreen_at?: string | null
  /**
   * Action Date
   * @format date-time
   */
  action_date: string
  /** Updated By */
  updated_by: string
  /** Note */
  note?: string | null
  status?: SaivaInternalSdkTherapyEnumsActionStatus | null
}

/** ActionOption */
export enum ActionOption {
  Connect = 'connect',
  Update = 'update',
  Disconnect = 'disconnect',
  Test = 'test'
}

/** ActionStatusExternal */
export interface ActionStatusExternal {
  progress_status: ActionStatusOptionExternal
  /** Success */
  success: boolean
  /** Org Id */
  org_id: string
  action: ActionOption
  /** Username */
  username: string
  /** Files Requested */
  files_requested?: number | null
  /** Files Uploaded */
  files_uploaded?: number | null
}

/** ActionStatusOption */
export enum ActionStatusOption {
  Complete = 'complete',
  Failed = 'failed',
  InProgress = 'in_progress'
}

/** ActionStatusOptionExternal */
export enum ActionStatusOptionExternal {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_FOUND = 'NOT_FOUND'
}

/** ActiveUsersWidgetData */
export interface ActiveUsersWidgetData {
  /** Name */
  name: string
  /** Last Active At */
  last_active_at: string
}

/** AnalyticsFacility[OpenRateAnalyticsItem] */
export interface AnalyticsFacilityOpenRateAnalyticsItem {
  /** Id */
  id: string
  /** Name */
  name: string
  data: OpenRateAnalyticsItem
}

/** AnalyticsFacility[PatientDaysAnalyticsItem] */
export interface AnalyticsFacilityPatientDaysAnalyticsItem {
  /** Id */
  id: string
  /** Name */
  name: string
  data: PatientDaysAnalyticsItem
}

/** AnalyticsFacility[PredictionsPerformanceAnalyticsItem] */
export interface AnalyticsFacilityPredictionsPerformanceAnalyticsItem {
  /** Id */
  id: string
  /** Name */
  name: string
  data: PredictionsPerformanceAnalyticsItem
}

/** AnalyticsOrganization[OpenRateAnalyticsItem] */
export interface AnalyticsOrganizationOpenRateAnalyticsItem {
  /** Id */
  id: string
  /** Name */
  name: string
  data: OpenRateAnalyticsItem
  /** Regions */
  regions: AnalyticsRegionOpenRateAnalyticsItem[]
}

/** AnalyticsOrganization[PatientDaysAnalyticsItem] */
export interface AnalyticsOrganizationPatientDaysAnalyticsItem {
  /** Id */
  id: string
  /** Name */
  name: string
  data: PatientDaysAnalyticsItem
  /** Regions */
  regions: AnalyticsRegionPatientDaysAnalyticsItem[]
}

/** AnalyticsOrganization[PredictionsPerformanceAnalyticsItem] */
export interface AnalyticsOrganizationPredictionsPerformanceAnalyticsItem {
  /** Id */
  id: string
  /** Name */
  name: string
  data: PredictionsPerformanceAnalyticsItem
  /** Regions */
  regions: AnalyticsRegionPredictionsPerformanceAnalyticsItem[]
}

/** AnalyticsQueryParams */
export interface AnalyticsQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /**
   * Is Quarterly
   * @default false
   */
  is_quarterly?: boolean
  /**
   * Include Date Range In Title
   * @default false
   */
  include_date_range_in_title?: boolean
}

/** AnalyticsRegion[OpenRateAnalyticsItem] */
export interface AnalyticsRegionOpenRateAnalyticsItem {
  /** Id */
  id: string
  /** Name */
  name: string
  data: OpenRateAnalyticsItem
  /** Facilities */
  facilities: AnalyticsFacilityOpenRateAnalyticsItem[]
}

/** AnalyticsRegion[PatientDaysAnalyticsItem] */
export interface AnalyticsRegionPatientDaysAnalyticsItem {
  /** Id */
  id: string
  /** Name */
  name: string
  data: PatientDaysAnalyticsItem
  /** Facilities */
  facilities: AnalyticsFacilityPatientDaysAnalyticsItem[]
}

/** AnalyticsRegion[PredictionsPerformanceAnalyticsItem] */
export interface AnalyticsRegionPredictionsPerformanceAnalyticsItem {
  /** Id */
  id: string
  /** Name */
  name: string
  data: PredictionsPerformanceAnalyticsItem
  /** Facilities */
  facilities: AnalyticsFacilityPredictionsPerformanceAnalyticsItem[]
}

/** AnalyticsReportType */
export enum AnalyticsReportType {
  DailyRiskReport = 'daily_risk_report',
  CrossFacilityRiskReport = 'cross_facility_risk_report',
  PredictionHitMissReport = 'prediction_hit_miss_report',
  OpenRates = 'open_rates',
  PredictionPerformanceReport = 'prediction_performance_report',
  UnplannedTransfers = 'unplanned_transfers',
  ResidentFollowup = 'resident_followup',
  ResidentAbnormalVitals = 'resident_abnormal_vitals',
  PatientDaysReport = 'patient_days_report',
  ViewedReports = 'viewed_reports',
  UserEngagementReport = 'user_engagement_report'
}

/** AnalyticsReportTypeWithoutDaily */
export enum AnalyticsReportTypeWithoutDaily {
  CrossFacilityRiskReport = 'cross_facility_risk_report',
  PredictionHitMissReport = 'prediction_hit_miss_report',
  OpenRates = 'open_rates',
  PredictionPerformanceReport = 'prediction_performance_report',
  UnplannedTransfers = 'unplanned_transfers',
  ResidentFollowup = 'resident_followup',
  ResidentAbnormalVitals = 'resident_abnormal_vitals',
  PatientDaysReport = 'patient_days_report',
  ViewedReports = 'viewed_reports',
  UserEngagementReport = 'user_engagement_report'
}

/** AnalyticsTable[OpenRateAnalyticsItem] */
export interface AnalyticsTableOpenRateAnalyticsItem {
  organization: AnalyticsOrganizationOpenRateAnalyticsItem
  /** Time Periods */
  time_periods: string[]
}

/** ApiTokenCreate */
export interface ApiTokenCreate {
  /** Name */
  name: string
  /** Scopes */
  scopes: ScopeOption[]
}

/** ApiTokenRead */
export interface ApiTokenRead {
  /** Name */
  name: string
  /** Scopes */
  scopes: ScopeOption[]
  /** Id */
  id: number
  /** Api Token */
  api_token: string
}

/** ApiTokenUpdate */
export interface ApiTokenUpdate {
  /** Name */
  name: string
}

/** AuthOption */
export enum AuthOption {
  PublicKey = 'public_key'
}

/** BaseAnalyticsQueryParams */
export interface BaseAnalyticsQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
}

/** Body_login_v2_auth_password_post */
export interface BodyLoginV2AuthPasswordPost {
  /** Grant Type */
  grant_type?: string | null
  /** Username */
  username: string
  /** Password */
  password: string
  /**
   * Scope
   * @default ""
   */
  scope?: string
  /** Client Id */
  client_id?: string | null
  /** Client Secret */
  client_secret?: string | null
}

/** Body_set_organization_logo_v3__product_name__orgs__org_id__settings_logo_post */
export interface BodySetOrganizationLogoV3ProductNameOrgsOrgIdSettingsLogoPost {
  /**
   * Logo
   * @format binary
   */
  logo: File
}

/** Body_set_organization_progress_notes_highlight_keywords_v2_orgs__org_id__settings_progress_notes_highlight_keywords_post */
export interface BodySetOrganizationProgressNotesHighlightKeywordsV2OrgsOrgIdSettingsProgressNotesHighlightKeywordsPost {
  /**
   * Progress Notes Highlight Keywords
   * @format binary
   */
  progress_notes_highlight_keywords: File
}

/** Body_v2_set_organization_logo_v2_orgs__org_id__settings_logo_post */
export interface BodyV2SetOrganizationLogoV2OrgsOrgIdSettingsLogoPost {
  /**
   * Logo
   * @format binary
   */
  logo: File
}

/** Capability */
export enum Capability {
  CanReceiveEmailNotifications = 'can_receive_email_notifications',
  CanReceivePushNotifications = 'can_receive_push_notifications',
  CanLoginToWebapp = 'can_login_to_webapp',
  CanLoginToMobileapp = 'can_login_to_mobileapp'
}

/** CardContentItem */
export interface CardContentItem {
  /** Datetime */
  datetime: string | null
  /** Description */
  description?: string | null
}

/** CovidDiagnosedValues */
export enum CovidDiagnosedValues {
  Yes = 'Yes',
  No = 'No'
}

/** CrossFacilityDailyRiskReport */
export interface CrossFacilityDailyRiskReport {
  /** Rows */
  rows: CrossFacilityDailyRiskReportRow[]
  filters: CrossFacilityDailyRiskReportFilter
  /** Facility View */
  facility_view: FacilityViewData[]
}

/** CrossFacilityDailyRiskReportFilter */
export interface CrossFacilityDailyRiskReportFilter {
  /** Payers */
  payers: string[]
  /** Physicians */
  physicians: string[]
}

/** CrossFacilityDailyRiskReportQueryParams */
export interface CrossFacilityDailyRiskReportQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /** Payers */
  payers?: string[] | null
  /** Physicians */
  physicians?: string[] | null
  /** @default "rank_today" */
  sort?: CrossFacilityDailyRiskReportSortingOption
  /**
   * Q
   * @default ""
   */
  q?: string
  /**
   * Facility View Q
   * @default ""
   */
  facility_view_q?: string
  /** Hidden Columns */
  hidden_columns?: string[]
}

/** CrossFacilityDailyRiskReportRow */
export interface CrossFacilityDailyRiskReportRow {
  /** Facility Name */
  facility_name: string
  /** Region Name */
  region_name: string
  /** Mrn */
  mrn: string
  patient_name: UrlValueField
  /** Unit */
  unit?: string | null
  /** Room */
  room?: string | null
  /** Rank Today */
  rank_today?: number | string | null
  /** Rank Yesterday */
  rank_yesterday?: string | null
  /** Rank Movement */
  rank_movement?: string | null
  /** Payer */
  payer?: string | null
  /** Physician */
  physician?: string | null
  /** Initial Admission Date */
  initial_admission_date?: string | null
  /** Last Transfer Date */
  last_transfer_date?: string | null
  /**
   * Visited
   * @default ""
   */
  visited?: string
  /**
   * New Patient
   * @default false
   */
  new_patient?: boolean
}

/** CrossFacilityDailyRiskReportSortingOption */
export enum CrossFacilityDailyRiskReportSortingOption {
  RankToday = 'rank_today',
  ValueRankToday = '-rank_today',
  RankMovement = 'rank_movement',
  ValueRankMovement = '-rank_movement',
  Physician = 'physician',
  ValuePhysician = '-physician',
  PatientName = 'patient_name',
  ValuePatientName = '-patient_name',
  FacilityName = 'facility_name',
  ValueFacilityName = '-facility_name',
  RegionName = 'region_name',
  ValueRegionName = '-region_name',
  FacilityRiskRank = 'facility_risk_rank',
  ValueFacilityRiskRank = '-facility_risk_rank'
}

/** CrossFacilityReportFacilityView */
export interface CrossFacilityReportFacilityView {
  /** Rows */
  rows: FacilityViewData[]
  filters: CrossFacilityDailyRiskReportFilter
}

/** DailyRiskReportFileUrl */
export interface DailyRiskReportFileUrl {
  /** Url */
  url: string
}

/** DashboardCard */
export interface DashboardCard {
  id: DashboardCardId
  /** Icon */
  icon?: string | null
  /** Title */
  title: string
  /** Subtitle */
  subtitle: string
  /**
   * Details
   * @default []
   */
  details?: string[]
  value_type: DashboardValueType
  /** Value */
  value: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: string
}

/** DashboardCardId */
export enum DashboardCardId {
  TotalResidents = 'total_residents',
  TotalFacilities = 'total_facilities',
  TotalStaff = 'total_staff',
  RecallRate = 'recall_rate',
  EmailOpenRate = 'email_open_rate',
  PatientDays = 'patient_days'
}

/** DashboardValueType */
export enum DashboardValueType {
  String = 'string'
}

/** DateInfoValuePair */
export interface DateInfoValuePair {
  /** Date Info */
  date_info: string
  /** Value */
  value: string
}

/** DayOfWeekOption */
export enum DayOfWeekOption {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday'
}

/** DueDateFilter */
export enum DueDateFilter {
  Past = 'past',
  Set = 'set',
  NotSet = 'not_set'
}

/** EhrAccountCreate */
export interface EhrAccountCreate {
  /** Id */
  id: string
  ehr_vendor: EhrVendor
}

/** EhrAccountRead */
export interface EhrAccountRead {
  /** Id */
  id: string
  ehr_vendor: EhrVendor
}

/** EhrAccountReadMultiple */
export interface EhrAccountReadMultiple {
  /** Items */
  items: EhrAccountRead[]
}

/** EhrAccountUpdate */
export interface EhrAccountUpdate {
  ehr_vendor: EhrVendor
}

/** EhrVendor */
export enum EhrVendor {
  Pcc = 'pcc',
  Sigmacare = 'sigmacare',
  Matrixcare = 'matrixcare'
}

/** EmailAnalyticsCreate */
export interface EmailAnalyticsCreate {
  /** Source Tracking Id */
  source_tracking_id: string
  /** User Email */
  user_email?: string | null
  /**
   * Open Count
   * @default 0
   */
  open_count?: number
  /** Organization Id */
  organization_id?: string | null
  report_type?: AnalyticsReportType | null
  /** Scheduled Report Id */
  scheduled_report_id?: string | null
}

/** EmailAnalyticsRead */
export interface EmailAnalyticsRead {
  /** Source Tracking Id */
  source_tracking_id: string
  /** User Email */
  user_email?: string | null
  /**
   * Open Count
   * @default 0
   */
  open_count?: number
  /** Created At */
  created_at?: string | null
  /** Last Opened At */
  last_opened_at?: string | null
  /** Organization Id */
  organization_id?: string | null
  report_type?: AnalyticsReportType | null
}

/** ErrorMessage */
export interface ErrorMessage {
  /** Reason */
  reason: string
  /** Message */
  message: string
  /** Status Code */
  status_code?: number | null
}

/** FacilityNameRead */
export interface FacilityNameRead {
  /** Id */
  id: number
  /** Name */
  name: string
}

/** FacilityProductLinkCreateOrUpdate */
export interface FacilityProductLinkCreateOrUpdate {
  /** @default "active" */
  status?: FacilityProductLinkStatusEnum
}

/** FacilityProductLinkRead */
export interface FacilityProductLinkRead {
  status: FacilityProductLinkStatusEnum
  /** Product Name */
  product_name: string
}

/** FacilityProductLinkReadMultiple */
export interface FacilityProductLinkReadMultiple {
  /** Items */
  items: FacilityProductLinkRead[]
}

/** FacilityProductLinkStatusEnum */
export enum FacilityProductLinkStatusEnum {
  Active = 'active',
  Inactive = 'inactive'
}

/** FacilityRead */
export interface FacilityRead {
  status: FacilityStatus
  /** Name */
  name: string
  /** Address */
  address: string | null
  /** Zipcode */
  zipcode: string | null
  /** City */
  city: string | null
  /** State */
  state: string | null
  /** Customers Identifier */
  customers_identifier: string
  /** Latitude */
  latitude: string | null
  /** Longitude */
  longitude: string | null
  /** Timezone */
  timezone?: string | null
  /** Id */
  id: number
  /** Region Id */
  region_id: number
}

/** FacilityReport */
export interface FacilityReport {
  /** Id */
  id: number
  /** Name */
  name: string
  /** Region Name */
  region_name: string
  /** Reports */
  reports: FacilityReportFile[]
}

/** FacilityReportFile */
export interface FacilityReportFile {
  /**
   * Date
   * @format date
   */
  date: string
  /** Id */
  id: string
}

/** FacilityServiceRead */
export interface FacilityServiceRead {
  /** Id */
  id: number
  /** Name */
  name: string
  /** Region Id */
  region_id: number
  status: FacilityStatus
  /** Address */
  address?: string | null
  /** Zipcode */
  zipcode?: string | null
  /** City */
  city?: string | null
  /** State */
  state?: string | null
  /** Customers Identifier */
  customers_identifier: string
  /** Latitude */
  latitude?: string | null
  /** Longitude */
  longitude?: string | null
  /** Timezone */
  timezone?: string | null
  /** Model Task Definition Tier */
  model_task_definition_tier: string
  /** Legacy Floor Unit Report Subscribers */
  legacy_floor_unit_report_subscribers?: object | null
  region: RegionServiceRead
  /** Imported From Ml Model Datasource Id */
  imported_from_ml_model_datasource_id: string
}

/** FacilityServiceReadMultiple */
export interface FacilityServiceReadMultiple {
  /** Items */
  items: FacilityServiceRead[]
}

/** FacilitySortingOption */
export enum FacilitySortingOption {
  Name = 'name',
  ValueName = '-name',
  Status = 'status',
  ValueStatus = '-status',
  Address = 'address',
  ValueAddress = '-address',
  Zipcode = 'zipcode',
  ValueZipcode = '-zipcode',
  City = 'city',
  ValueCity = '-city',
  State = 'state',
  ValueState = '-state',
  Region = 'region',
  ValueRegion = '-region'
}

/** FacilityStatus */
export enum FacilityStatus {
  Active = 'active',
  InProgress = 'in_progress',
  OnHold = 'on_hold',
  OffBoarding = 'off_boarding',
  Suspended = 'suspended',
  Cancelled = 'cancelled'
}

/** FacilityUpdate */
export interface FacilityUpdate {
  /** Name */
  name?: string | null
  /** Address */
  address?: string | null
  /** Zipcode */
  zipcode?: string | null
  /** City */
  city?: string | null
  /** State */
  state?: string | null
  /** Region Id */
  region_id?: number | null
  /** Latitude */
  latitude?: number | string | null
  /** Longitude */
  longitude?: number | string | null
}

/** FacilityViewData */
export interface FacilityViewData {
  /** Facility Name */
  facility_name: string
  /** Region Name */
  region_name: string
  /** Number Of Patients */
  number_of_patients: number
  /** Visited Patients Percentage */
  visited_patients_percentage: string
  /** Avg Rank Today */
  avg_rank_today: string
  /** Facility Risk Rank */
  facility_risk_rank: number | string
}

/** FacilityWithRegionData */
export interface FacilityWithRegionData {
  /** Facility Id */
  facility_id: number
  /** Facility Name */
  facility_name: string
  /** Region Id */
  region_id: number
  /** Region Name */
  region_name: string
}

/** FailedReason */
export enum FailedReason {
  PatientDeclinedTreatment = 'patient_declined_treatment',
  PatientNotInRoom = 'patient_not_in_room',
  PatientAsleep = 'patient_asleep',
  Other = 'other'
}

/** FcmStatusRead */
export interface FcmStatusRead {
  /** @default "Pending" */
  status?: OtpFcmStatus
  /** Access Token */
  access_token?: string | null
  /** Refresh Token */
  refresh_token?: string | null
  /** Error Message */
  error_message?: string
}

/** FileFormat */
export enum FileFormat {
  Json = 'json'
}

/** FileFormatOption */
export enum FileFormatOption {
  Csv = 'csv',
  Pdf = 'pdf'
}

/** FilterType */
export enum FilterType {
  HospitalAdmissions = 'hospital_admissions'
}

/** FrontendPlatform */
export enum FrontendPlatform {
  Ios = 'ios',
  Android = 'android',
  Web = 'web'
}

/** GenAIRead */
export interface GenAIRead {
  /** Input Text */
  input_text: string
}

/** GenAIResponse */
export interface GenAIResponse {
  /** Output Text */
  output_text: string
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** HitMissReport */
export interface HitMissReport {
  /** Data */
  data: HitMissReportItem[]
  /** Cumulative Data */
  cumulative_data: HitMissReportCumulativeItem[]
  filters: HitMissReportFilter
  /** Stats */
  stats: HitMissReportStats[]
}

/** HitMissReportCumulativeData */
export interface HitMissReportCumulativeData {
  /** Data */
  data: HitMissReportCumulativeItem[]
  filters: HitMissReportFilter
  /** Stats */
  stats: HitMissReportStats[]
}

/** HitMissReportCumulativeItem */
export interface HitMissReportCumulativeItem {
  /** Risk Rank */
  risk_rank: string
  /**
   * Number Of Residents
   * @format integer
   */
  number_of_residents: string
  /** Risk Rank Range */
  risk_rank_range: string
  /**
   * Cumulative Residents
   * @format integer
   */
  cumulative_residents: string
  /**
   * Cumulative Upt
   * @format float
   */
  cumulative_upt: string
}

/** HitMissReportFilter */
export interface HitMissReportFilter {
  /** Outcome */
  outcome: string[]
  /** Payer Type */
  payer_type: string[]
}

/** HitMissReportItem */
export interface HitMissReportItem {
  /** Facility Name */
  facility_name?: string | null
  /** Region Name */
  region_name?: string | null
  /** Date Of Transfer */
  date_of_transfer?: string | null
  /** Rank */
  rank?: number | string | null
  /** Mrn */
  mrn?: string | null
  patient_name?: UrlValueField | null
  /** Best 4 Day Rank */
  best_4_day_rank?: number | string | null
  /** Outcome */
  outcome?: string | null
  /** Length Of Stay */
  length_of_stay?: number | string | null
  /** Planned */
  planned?: string | null
  /** Predicted */
  predicted?: string | null
  /** Payer Type */
  payer_type?: string | null
  /** Payer */
  payer?: string | null
}

/** HitMissReportQueryParams */
export interface HitMissReportQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /**
   * Predicted
   * @default ["Yes","No"]
   */
  predicted?: string[]
  /**
   * Planned
   * @default ["Yes","No","Unspecified"]
   */
  planned?: string[]
  /** Outcome */
  outcome?: string[]
  /** Payer Type */
  payer_type?: string[] | null
  /**
   * Filter 30 Days
   * @default true
   */
  filter_30_days?: boolean
}

/** HitMissReportStatType */
export enum HitMissReportStatType {
  Number = 'number',
  Percentage = 'percentage'
}

/** HitMissReportStats */
export interface HitMissReportStats {
  /**
   * Type
   * @default "group"
   */
  type?: string
  /** Label */
  label: string
  /** Items */
  items: HitMissReportStatsItem[]
}

/** HitMissReportStatsItem */
export interface HitMissReportStatsItem {
  type: HitMissReportStatType
  /** Label */
  label: string
  data: HitMissReportStatsItemData
  /** Date Range */
  date_range: string
}

/** HitMissReportStatsItemData */
export interface HitMissReportStatsItemData {
  /** Value */
  value: string
  /** Increase */
  increase?: boolean | null
}

/** IntegrationCatalogListRead */
export interface IntegrationCatalogListRead {
  /** Active Integrations */
  active_integrations: IntegrationCatalogRead[]
  /** Inactive Integrations */
  inactive_integrations: IntegrationCatalogRead[]
}

/** IntegrationCatalogRead */
export interface IntegrationCatalogRead {
  /** Id */
  id: string
  /** Label */
  label: string
  /** Description */
  description: string
  /** Short Description */
  short_description: string
  /** User Guide Url */
  user_guide_url?: string | null
  /** Icon */
  icon?: string | null
  /** @default "disconnected" */
  status?: IntegrationStatusOption
  /**
   * Is Enabled
   * @default false
   */
  is_enabled?: boolean
  /** Values */
  values?: object | null
}

/** IntegrationStatusOption */
export enum IntegrationStatusOption {
  Connecting = 'connecting',
  Connected = 'connected',
  Updating = 'updating',
  Disconnecting = 'disconnecting',
  Disconnected = 'disconnected',
  Unavailable = 'unavailable'
}

/** InvitationAccept */
export interface InvitationAccept {
  /** Token */
  token: string
}

/** InvitationCreate */
export interface InvitationCreate {
  /**
   * Email
   * @format email
   */
  email: string
  /** Title Id */
  title_id: string
  /** Name */
  name: string
  role: SaivaRole
  /**
   * Enabled
   * @default true
   */
  enabled?: boolean
  regions: RegionAccessCreateRead
  /** Capabilities */
  capabilities: Capability[]
}

/** InvitationRead */
export interface InvitationRead {
  /**
   * Email
   * @format email
   */
  email: string
  /** Title Id */
  title_id: string
  /** Name */
  name: string
  role: SaivaRole
  /**
   * Enabled
   * @default true
   */
  enabled?: boolean
  regions: RegionAccessCreateRead
  /** Capabilities */
  capabilities: Capability[]
  /** Id */
  id: number
  /**
   * Created At
   * @format date-time
   */
  created_at: string
  /**
   * Expires At
   * @format date-time
   */
  expires_at: string
  /** Invited By */
  invited_by?: string | null
  status: InvitationStatus
}

/** InvitationSortOption */
export enum InvitationSortOption {
  Email = 'email',
  ValueEmail = '-email',
  UserEmail = 'user.email',
  ValueUserEmail = '-user.email',
  CreatedAt = 'created_at',
  ValueCreatedAt = '-created_at'
}

/** InvitationStatus */
export enum InvitationStatus {
  Invited = 'invited',
  Accepted = 'accepted',
  Deleted = 'deleted'
}

/** InvitationUpdate */
export interface InvitationUpdate {
  /**
   * Email
   * @format email
   */
  email: string
  /** Title Id */
  title_id: string
  /** Name */
  name: string
  role: SaivaRole
  /**
   * Enabled
   * @default true
   */
  enabled?: boolean
  regions: RegionAccessCreateRead
  /** Capabilities */
  capabilities: Capability[]
}

/** KeywordHighlight */
export interface KeywordHighlight {
  /** Date */
  date?: string | null
  /** Time */
  time: string
  /** Progress Note Type */
  progress_note_type: string
  /** Days Lapsed */
  days_lapsed: string
  /** Clean Note */
  clean_note: string
}

/** Keywords */
export interface Keywords {
  /** Keywords */
  keywords: string[]
  /** Items */
  items: KeywordHighlight[]
}

/** LLMType */
export enum LLMType {
  Titan = 'titan',
  Claude = 'claude'
}

/** LatestRuleEngineRun */
export interface LatestRuleEngineRun {
  /** Latest Rule Engine Run */
  latest_rule_engine_run?: string | null
}

/** LegacyUserMeRead */
export interface LegacyUserMeRead {
  /** Id */
  id: number
  /**
   * Email
   * @format email
   */
  email: string
  /** Title */
  title: string
  /** Name */
  name: string
  /** Is Superuser */
  is_superuser: boolean
}

/** ListFacilitiesParams */
export interface ListFacilitiesParams {
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 1000
   * @default 50
   */
  size?: number
  /** Q */
  q?: string | null
  /** @default "name" */
  sort?: FacilitySortingOption | null
}

/** ListOrganizationsParams */
export interface ListOrganizationsParams {
  /** Q */
  q?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 1000
   * @default 1000
   */
  size?: number
  /** Status */
  status?: OrganizationStatus[] | null
  /** @default "name" */
  sort?: OrganizationSortOption | null
}

/** LoginTokens */
export interface LoginTokens {
  /** Access Token */
  access_token: string
  /** Refresh Token */
  refresh_token: string
}

/** Logout */
export interface Logout {
  /** Refresh Token */
  refresh_token: string
  /** Device Id */
  device_id?: string | null
}

/** MlModelConfigOutputCreate */
export interface MlModelConfigOutputCreate {
  type: MlModelConfigOutputType
  pdf_report: MlModelConfigOutputPdfReportRead
}

/** MlModelConfigOutputPdfReportRead */
export interface MlModelConfigOutputPdfReportRead {
  version: MlModelOutputPdfReportVersion
  /** Unique Keys Db Sync Parameter */
  unique_keys_db_sync_parameter?: string | null
}

/** MlModelConfigOutputRead */
export interface MlModelConfigOutputRead {
  type: MlModelConfigOutputType
  pdf_report: MlModelConfigOutputPdfReportRead
  /** S3 Bucket Name */
  s3_bucket_name: string
  /** S3 Prefix */
  s3_prefix: string
}

/** MlModelConfigOutputType */
export enum MlModelConfigOutputType {
  PdfReport = 'pdf_report'
}

/** MlModelConfigOutputUpdate */
export interface MlModelConfigOutputUpdate {
  type: MlModelConfigOutputType
  pdf_report: MlModelConfigOutputPdfReportRead
  /** S3 Bucket Name */
  s3_bucket_name?: string | null
  /** S3 Prefix */
  s3_prefix?: string | null
}

/** MlModelDatasourceCreateOrUpdate */
export interface MlModelDatasourceCreateOrUpdate {
  scraping?: MlModelDatasourceScrapingCreate | null
  sftp?: MlModelDatasourceSftpCreateOrUpdate | null
  dbrelay?: MlModelDatasourceDbrelayCreateOrUpdate | null
  type?: MlModelDatasourceType | null
  etl_version?: MlModelDatasourceEtlVersion | null
  /** Database Name */
  database_name?: string | null
  /** Database Credentials Secret Id */
  database_credentials_secret_id?: string | null
  datasource_vendor?: EhrVendor | null
  ingestion?: MlModelDatasourceIngestion | null
  /** Id */
  id?: string | null
}

/** MlModelDatasourceCreateWithId */
export interface MlModelDatasourceCreateWithId {
  scraping?: MlModelDatasourceScrapingCreate | null
  sftp?: MlModelDatasourceSftpCreateOrUpdate | null
  dbrelay?: MlModelDatasourceDbrelayCreateOrUpdate | null
  type: MlModelDatasourceType
  etl_version: MlModelDatasourceEtlVersion
  /** Database Name */
  database_name: string
  /** Database Credentials Secret Id */
  database_credentials_secret_id: string
  datasource_vendor: EhrVendor
  ingestion?: MlModelDatasourceIngestion | null
  /** Id */
  id: string
}

/** MlModelDatasourceDbrelayCreateOrUpdate */
export interface MlModelDatasourceDbrelayCreateOrUpdate {
  /** Source Diff Files S3 Bucket Name */
  source_diff_files_s3_bucket_name: string
  /** Source Diff Files S3 Prefix */
  source_diff_files_s3_prefix: string
  /** Diff Files S3 Bucket Name */
  diff_files_s3_bucket_name: string
  /** Diff Files S3 Prefix */
  diff_files_s3_prefix: string
}

/** MlModelDatasourceDbrelayRead */
export interface MlModelDatasourceDbrelayRead {
  /** Source Diff Files S3 Bucket Name */
  source_diff_files_s3_bucket_name: string
  /** Source Diff Files S3 Prefix */
  source_diff_files_s3_prefix: string
  /** Diff Files S3 Bucket Name */
  diff_files_s3_bucket_name: string
  /** Diff Files S3 Prefix */
  diff_files_s3_prefix: string
}

/** MlModelDatasourceEtlVersion */
export enum MlModelDatasourceEtlVersion {
  V3 = 'v3',
  V4 = 'v4',
  V5 = 'v5',
  V6 = 'v6'
}

/** MlModelDatasourceIngestion */
export interface MlModelDatasourceIngestion {
  /** Customer Sftp Logs Path */
  customer_sftp_logs_path?: string | null
  /** Customer Sftp Full Backup Path */
  customer_sftp_full_backup_path?: string | null
  /** Customer Sftp Delete Processed Files */
  customer_sftp_delete_processed_files?: boolean | null
  /** S3 Bucket Name */
  s3_bucket_name?: string | null
  /** Logs S3 Prefix */
  logs_s3_prefix?: string | null
  /** Full Backup S3 Prefix */
  full_backup_s3_prefix?: string | null
}

/** MlModelDatasourceRead */
export interface MlModelDatasourceRead {
  type: MlModelDatasourceType
  etl_version: MlModelDatasourceEtlVersion
  /** Database Name */
  database_name: string
  /** Database Credentials Secret Id */
  database_credentials_secret_id: string
  datasource_vendor: EhrVendor
  ingestion?: MlModelDatasourceIngestion | null
  /** Id */
  id: string
  scraping?: MlModelDatasourceScrapingRead | null
  sftp?: MlModelDatasourceSftpRead | null
  dbrelay?: MlModelDatasourceDbrelayRead | null
}

/** MlModelDatasourceReadMultiple */
export interface MlModelDatasourceReadMultiple {
  /** Items */
  items: MlModelDatasourceRead[]
}

/** MlModelDatasourceScrapingCreate */
export interface MlModelDatasourceScrapingCreate {
  /** Credentials Secret Id */
  credentials_secret_id: string
  /** Uses Okta Auth */
  uses_okta_auth: boolean
  /** Fake Masterpatient Ids */
  fake_masterpatient_ids: boolean
}

/** MlModelDatasourceScrapingRead */
export interface MlModelDatasourceScrapingRead {
  /** Credentials Secret Id */
  credentials_secret_id: string
  /** Uses Okta Auth */
  uses_okta_auth: boolean
  /** Fake Masterpatient Ids */
  fake_masterpatient_ids: boolean
  /** Files S3 Bucket Name */
  files_s3_bucket_name: string
  /** Files S3 Prefix */
  files_s3_prefix: string
}

/** MlModelDatasourceSftpCreateOrUpdate */
export interface MlModelDatasourceSftpCreateOrUpdate {
  /** Source Diff Files S3 Bucket Name */
  source_diff_files_s3_bucket_name: string
  /** Source Diff Files S3 Prefix */
  source_diff_files_s3_prefix: string
  /** Diff Files S3 Bucket Name */
  diff_files_s3_bucket_name: string
  /** Diff Files S3 Prefix */
  diff_files_s3_prefix: string
}

/** MlModelDatasourceSftpRead */
export interface MlModelDatasourceSftpRead {
  /** Source Diff Files S3 Bucket Name */
  source_diff_files_s3_bucket_name: string
  /** Source Diff Files S3 Prefix */
  source_diff_files_s3_prefix: string
  /** Diff Files S3 Bucket Name */
  diff_files_s3_bucket_name: string
  /** Diff Files S3 Prefix */
  diff_files_s3_prefix: string
}

/** MlModelDatasourceType */
export enum MlModelDatasourceType {
  DirectDbrelay = 'direct_dbrelay',
  Dbrelay = 'dbrelay',
  Scraping = 'scraping',
  Sftp = 'sftp'
}

/** MlModelDatasourceUpdate */
export interface MlModelDatasourceUpdate {
  scraping?: MlModelDatasourceScrapingCreate | null
  sftp?: MlModelDatasourceSftpCreateOrUpdate | null
  dbrelay?: MlModelDatasourceDbrelayCreateOrUpdate | null
  type?: MlModelDatasourceType | null
  etl_version?: MlModelDatasourceEtlVersion | null
  /** Database Name */
  database_name?: string | null
  /** Database Credentials Secret Id */
  database_credentials_secret_id?: string | null
  datasource_vendor?: EhrVendor | null
  ingestion?: MlModelDatasourceIngestion | null
}

/** MlModelFacilityConfigServiceCreate */
export interface MlModelFacilityConfigServiceCreate {
  /** Missing Datasets */
  missing_datasets: string[]
  group_level?: MlModelGroupLevel | null
  /**
   * Golive Date
   * @format date
   */
  golive_date: string
  /** Trained Model Id */
  trained_model_id?: string | null
  /** Customers Facility Identifier */
  customers_facility_identifier: string
}

/** MlModelFacilityConfigServiceRead */
export interface MlModelFacilityConfigServiceRead {
  /** Missing Datasets */
  missing_datasets: string[]
  group_level?: MlModelGroupLevel | null
  /**
   * Golive Date
   * @format date
   */
  golive_date: string
  /** Trained Model Id */
  trained_model_id?: string | null
  /**
   * Id
   * @format uuid
   */
  id: string
  facility: FacilityServiceRead
  trained_model?: MlTrainedModelRead | null
  /** Model Data Facilities Override */
  model_data_facilities_override?: string[] | null
}

/** MlModelFacilityConfigServiceReadMultiple */
export interface MlModelFacilityConfigServiceReadMultiple {
  /** Items */
  items: MlModelFacilityConfigServiceRead[]
}

/** MlModelFacilityConfigServiceUpdate */
export interface MlModelFacilityConfigServiceUpdate {
  /** Missing Datasets */
  missing_datasets: string[]
  group_level?: MlModelGroupLevel | null
  /**
   * Golive Date
   * @format date
   */
  golive_date: string
  /** Trained Model Id */
  trained_model_id?: string | null
}

/** MlModelGroupLevel */
export enum MlModelGroupLevel {
  Doctor = 'doctor',
  Facility = 'facility',
  Unit = 'unit',
  Floor = 'floor'
}

/** MlModelOrganizationConfigServiceCreate */
export interface MlModelOrganizationConfigServiceCreate {
  status: MlModelOrganizationConfigStatus
  /**
   * Cron Schedule
   * @pattern (@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})
   */
  cron_schedule: string
  /** Use Fargate Spot */
  use_fargate_spot: boolean
  /** Cutoff Ratio */
  cutoff_ratio: number | string
  /** Model Config */
  model_config: object
  /** Training Metadata */
  training_metadata: object
  ingestion?: MlModelDatasourceIngestion | null
  /** Trained Model Id */
  trained_model_id?: string | null
  /** Id */
  id?: string | null
  /** Ml Model Type Id */
  ml_model_type_id: string
  input: MlModelDatasourceCreateOrUpdate
  output: MlModelConfigOutputCreate
}

/** MlModelOrganizationConfigServiceRead */
export interface MlModelOrganizationConfigServiceRead {
  /** Id */
  id: string
  status: MlModelOrganizationConfigStatus
  /** Organization Id */
  organization_id: string
  ml_model_type: MlModelTypeServiceRead
  input: MlModelDatasourceRead
  output: MlModelConfigOutputRead
  ingestion: MlModelDatasourceIngestion
  /** Cron Schedule */
  cron_schedule: string
  /** Use Fargate Spot */
  use_fargate_spot: boolean
  /** Cutoff Ratio */
  cutoff_ratio: string
  /** Model Config */
  model_config: object
  /** Training Metadata */
  training_metadata: object
  /** Facilities */
  facilities: MlModelFacilityConfigServiceRead[]
  trained_model?: MlTrainedModelRead | null
}

/** MlModelOrganizationConfigServiceReadMultiple */
export interface MlModelOrganizationConfigServiceReadMultiple {
  /** Items */
  items: MlModelOrganizationConfigServiceRead[]
}

/** MlModelOrganizationConfigServiceUpdate */
export interface MlModelOrganizationConfigServiceUpdate {
  status?: MlModelOrganizationConfigStatus | null
  /** Cron Schedule */
  cron_schedule?: string | null
  /** Use Fargate Spot */
  use_fargate_spot?: boolean | null
  /** Cutoff Ratio */
  cutoff_ratio?: number | string | null
  /** Model Config */
  model_config?: object | null
  /** Training Metadata */
  training_metadata?: object | null
  ingestion?: MlModelDatasourceIngestion | null
  /** Trained Model Id */
  trained_model_id?: string | null
  input?: MlModelDatasourceCreateOrUpdate | null
  output?: MlModelConfigOutputUpdate | null
}

/** MlModelOrganizationConfigStatus */
export enum MlModelOrganizationConfigStatus {
  InDevelopment = 'in_development',
  InReview = 'in_review',
  AbTesting = 'ab_testing',
  Published = 'published',
  Removed = 'removed'
}

/** MlModelOutputPdfReportVersion */
export enum MlModelOutputPdfReportVersion {
  V2 = 'v2',
  V3 = 'v3',
  V4 = 'v4',
  V6 = 'v6'
}

/** MlModelTypeServiceCreate */
export interface MlModelTypeServiceCreate {
  /** Id */
  id: string
  /** Name */
  name: string
  /** Quality Measure */
  quality_measure: string
  /** Ecs Task Definition */
  ecs_task_definition: string
  /** Diagnosis Lookup Ccs S3 Uri */
  diagnosis_lookup_ccs_s3_uri: string
}

/** MlModelTypeServiceRead */
export interface MlModelTypeServiceRead {
  /** Id */
  id: string
  /** Name */
  name: string
  /** Quality Measure */
  quality_measure: string
  /**
   * Versions
   * @default []
   */
  versions?: MlModelTypeVersionRead[]
}

/** MlModelTypeServiceReadMultiple */
export interface MlModelTypeServiceReadMultiple {
  /** Items */
  items: MlModelTypeServiceRead[]
}

/** MlModelTypeVersionRead */
export interface MlModelTypeVersionRead {
  /**
   * Id
   * @format uuid
   */
  id: string
  /** Version */
  version: string
  /** Ml Model Type Id */
  ml_model_type_id: string
  /** Ecs Task Definition */
  ecs_task_definition: string
  /** Diagnosis Lookup Ccs S3 Uri */
  diagnosis_lookup_ccs_s3_uri: string
}

/** MlTrainedModelCreate */
export interface MlTrainedModelCreate {
  /** Mlflow Model Id */
  mlflow_model_id?: string | null
  /** Ml Model Organization Config Id */
  ml_model_organization_config_id?: string | null
  /**
   * Ml Model Type Version Id
   * @format uuid
   */
  ml_model_type_version_id: string
  status: MlTrainedModelStatus
  /** Released At */
  released_at?: string | null
  /** Description */
  description?: string | null
  /** Vector Model */
  vector_model?: string | null
  /** Model S3 Folder */
  model_s3_folder?: string | null
  /** Train Start Date */
  train_start_date?: string | null
  /** Train End Date */
  train_end_date?: string | null
  /** Validation Start Date */
  validation_start_date?: string | null
  /** Validation End Date */
  validation_end_date?: string | null
  /** Test Start Date */
  test_start_date?: string | null
  /** Test End Date */
  test_end_date?: string | null
  /** Test Auc */
  test_auc?: number | null
  /** Test Recall At Cutoff Ratio */
  test_recall_at_cutoff_ratio?: number | null
  /** Model Algorithm */
  model_algorithm?: string | null
  /** Additional Metadata */
  additional_metadata: object
  /** Missing Datasets */
  missing_datasets: string[]
}

/** MlTrainedModelRead */
export interface MlTrainedModelRead {
  /**
   * Id
   * @format uuid
   */
  id: string
  status: MlTrainedModelStatus
  /** Mlflow Model Id */
  mlflow_model_id?: string | null
  /** Ml Model Organization Config Id */
  ml_model_organization_config_id?: string | null
  ml_model_type_version: MlModelTypeVersionRead
  /** Released At */
  released_at?: string | null
  /** Description */
  description?: string | null
  /** Vector Model */
  vector_model?: string | null
  /** Model S3 Folder */
  model_s3_folder?: string | null
  /** Train Start Date */
  train_start_date?: string | null
  /** Train End Date */
  train_end_date?: string | null
  /** Validation Start Date */
  validation_start_date?: string | null
  /** Validation End Date */
  validation_end_date?: string | null
  /** Test Start Date */
  test_start_date?: string | null
  /** Test End Date */
  test_end_date?: string | null
  /** Test Auc */
  test_auc?: number | null
  /** Test Recall At Cutoff Ratio */
  test_recall_at_cutoff_ratio?: number | null
  /** Model Algorithm */
  model_algorithm?: string | null
  /** Missing Datasets */
  missing_datasets: string[]
  /** Additional Metadata */
  additional_metadata: object
}

/** MlTrainedModelReadMultiple */
export interface MlTrainedModelReadMultiple {
  /** Items */
  items: MlTrainedModelRead[]
}

/** MlTrainedModelStatus */
export enum MlTrainedModelStatus {
  InDevelopment = 'in_development',
  InReview = 'in_review',
  Ready = 'ready',
  Released = 'released',
  Removed = 'removed'
}

/** OldQualityMeasure */
export enum OldQualityMeasure {
  Rth = 'rth',
  Fall = 'fall',
  Wound = 'wound'
}

/** OpenRateAnalyticsDetail */
export interface OpenRateAnalyticsDetail {
  /**
   * Value
   * @format integer
   */
  value: string
  /** Detail Url */
  detail_url?: string | null
}

/** OpenRateAnalyticsItem */
export interface OpenRateAnalyticsItem {
  /** Subscribers */
  subscribers: string[]
  /** Sent */
  sent: string[]
  /** Opened */
  opened: OpenRateAnalyticsDetail[]
  /** Facility Open Rate */
  facility_open_rate: string[]
}

/** OrganizationRead */
export interface OrganizationRead {
  /** Name */
  name: string
  status: OrganizationStatus
  /** Address */
  address: string | null
  /** Zipcode */
  zipcode: string | null
  /** City */
  city: string | null
  /** State */
  state: string | null
  /** Customer Type */
  customer_type?: string | null
  /** Partner Id */
  partner_id: string | null
  /** Id */
  id: string
}

/** OrganizationServiceCreate */
export interface OrganizationServiceCreate {
  /** Id */
  id: string
  /** Name */
  name: string
  /** Is Active */
  is_active: boolean
  /** Address */
  address?: string | null
  /** Zipcode */
  zipcode?: string | null
  /** City */
  city?: string | null
  /** State */
  state?: string | null
  /** Customer Type */
  customer_type?: string | null
  /** Partner Id */
  partner_id?: string | null
}

/** OrganizationServiceGetProductFeatures */
export interface OrganizationServiceGetProductFeatures {
  /** Items */
  items: ProductFeatureServiceRead[]
}

/** OrganizationServiceRead */
export interface OrganizationServiceRead {
  /** Name */
  name: string
  status: OrganizationStatus
  /** Address */
  address: string | null
  /** Zipcode */
  zipcode: string | null
  /** City */
  city: string | null
  /** State */
  state: string | null
  /** Customer Type */
  customer_type?: string | null
  /** Partner Id */
  partner_id: string | null
  /** Id */
  id: string
}

/** OrganizationServiceReadMultiple */
export interface OrganizationServiceReadMultiple {
  /** Items */
  items: OrganizationServiceRead[]
}

/** OrganizationServiceUpdate */
export interface OrganizationServiceUpdate {
  /** Name */
  name?: string | null
  status?: OrganizationStatus | null
  /** Address */
  address?: string | null
  /** Zipcode */
  zipcode?: string | null
  /** City */
  city?: string | null
  /** State */
  state?: string | null
  /** Customer Type */
  customer_type?: string | null
  /** Partner Id */
  partner_id?: string | null
}

/** OrganizationSettingsLogoRead */
export interface OrganizationSettingsLogoRead {
  /** Report Logo Url */
  report_logo_url?: string | null
}

/** OrganizationSettingsProgressNotesHighlightKeywordsRead */
export interface OrganizationSettingsProgressNotesHighlightKeywordsRead {
  /** File Url */
  file_url: string
}

/** OrganizationSettingsRead */
export interface OrganizationSettingsRead {
  /** Email From Address */
  email_from_address?: string | null
  /** Feature Values */
  feature_values?: object | null
  /** Packages */
  packages: ProductPackagePublicRead[]
  /** Enabled Users */
  enabled_users: number
  sending_report_type: SendingReportType
}

/** OrganizationSettingsServiceRead */
export interface OrganizationSettingsServiceRead {
  /** Report Logo S3 Uri */
  report_logo_s3_uri?: string | null
  /** Paubox Credentials Secret Id */
  paubox_credentials_secret_id?: string | null
  /** Email From Address */
  email_from_address?: string | null
  /** Report Progress Notes Highlight Keywords S3 Uri */
  report_progress_notes_highlight_keywords_s3_uri?: string | null
  /** Whitelabel Branding */
  whitelabel_branding: boolean
  /** Daily Risk Report Email Subject */
  daily_risk_report_email_subject: string
  /** Sending Report Type */
  sending_report_type: string
}

/** OrganizationSettingsUpdate */
export interface OrganizationSettingsUpdate {
  /** Email From Address */
  email_from_address?: string | null
  sending_report_type?: SendingReportType | null
}

/** OrganizationSettingsValidFilterValuesUpdate */
export interface OrganizationSettingsValidFilterValuesUpdate {
  /** Values */
  values: (string | null)[]
}

/** OrganizationSettingsValidFiltersRead */
export interface OrganizationSettingsValidFiltersRead {
  /** Valid Filters */
  valid_filters: ValidFiltersRead[]
}

/** OrganizationSortOption */
export enum OrganizationSortOption {
  Name = 'name',
  ValueName = '-name',
  Id = 'id',
  ValueId = '-id',
  Status = 'status',
  ValueStatus = '-status'
}

/** OrganizationStatus */
export enum OrganizationStatus {
  Active = 'active',
  InProgress = 'in_progress',
  OnHold = 'on_hold',
  OffBoarding = 'off_boarding',
  OffBoarded = 'off_boarded',
  StopBill = 'stop_bill',
  Suspended = 'suspended',
  Cancelled = 'cancelled'
}

/** OrganizationUpdate */
export interface OrganizationUpdate {
  /** Name */
  name?: string | null
  /** Address */
  address?: string | null
  /** Zipcode */
  zipcode?: string | null
  /** City */
  city?: string | null
  /** State */
  state?: string | null
}

/** OtpFcmStatus */
export enum OtpFcmStatus {
  Accepted = 'Accepted',
  Declined = 'Declined',
  Pending = 'Pending',
  Failed = 'Failed',
  Expired = 'Expired'
}

/** OtpFcmStatusChange */
export interface OtpFcmStatusChange {
  /** @default "Pending" */
  status?: OtpFcmStatus
  /** Token */
  token?: string | null
  /** User Id */
  user_id?: number | null
}

/** OtpLogin */
export interface OtpLogin {
  /**
   * Email
   * @format email
   */
  email: string
  /** @default "ios" */
  platform?: FrontendPlatform | null
  /**
   * Email Login
   * @default false
   */
  email_login?: boolean
}

/** OtpLoginWithPassword */
export interface OtpLoginWithPassword {
  /**
   * Email
   * @format email
   */
  email: string
  platform: FrontendPlatform
  /**
   * Email Login
   * @default false
   */
  email_login?: boolean
  /** Password */
  password: string
  /** Phrase */
  phrase: string
}

/** OtpPhrase */
export interface OtpPhrase {
  /** Phrase */
  phrase: string
  /** User Id */
  user_id?: number
  /**
   * Active Devices
   * @default false
   */
  active_devices?: boolean
}

/** PCCOrderStatus */
export enum PCCOrderStatus {
  Active = 'Active',
  OnHold = 'On Hold',
  PendingConfirmation = 'Pending Confirmation',
  PendingOrderSignature = 'Pending Order Signature',
  PendingClinicalReview = 'Pending Clinical Review',
  Discontinued = 'Discontinued',
  Completed = 'Completed',
  StruckOut = 'Struck Out'
}

/** PagedFacilityRead */
export interface PagedFacilityRead {
  /** Items */
  items: FacilityRead[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
}

/** PagedInvitationRead */
export interface PagedInvitationRead {
  /** Items */
  items: InvitationRead[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
}

/** PagedOrganizationRead */
export interface PagedOrganizationRead {
  /** Items */
  items: OrganizationRead[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
}

/** PagedOrganizationServiceRead */
export interface PagedOrganizationServiceRead {
  /** Items */
  items: OrganizationServiceRead[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
}

/** PagedParentOrganizationRead */
export interface PagedParentOrganizationRead {
  /** Items */
  items: ParentOrganizationRead[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
}

/**
 * PagedPatientRead
 * Represents a Paged Patient response schema.
 */
export interface PagedPatientRead {
  /** Items */
  items: PatientRead[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
}

/** PagedPatientScreenWithMapping */
export interface PagedPatientScreenWithMapping {
  /** Email To Name Mapping */
  email_to_name_mapping: Record<string, string>
  /** Items */
  items: PatientScreenRead[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
}

/** PagedScheduledReportRead */
export interface PagedScheduledReportRead {
  /** Items */
  items: ScheduledReportRead[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
}

/**
 * PagedUnscreenedPatientRead
 * Represents a Paged Unscreened Patients response schema.
 */
export interface PagedUnscreenedPatientRead {
  /** Items */
  items: UnscreenedPatientRead[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
}

/** PagedUserRead */
export interface PagedUserRead {
  /** Items */
  items: UserRead[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
}

/** PagedWoundCareReadData */
export interface PagedWoundCareReadData {
  /** Items */
  items: WoundCareReadData[]
  /** Total */
  total: number
  /** Page */
  page: number
  /** Size */
  size: number
  /** Pages */
  pages: number
  stats: WoundCareStatisticData
  filters: WoundCareFilter
}

/** ParentOrganizationRead */
export interface ParentOrganizationRead {
  /** Name */
  name: string
  status: OrganizationStatus
  /** Address */
  address: string | null
  /** Zipcode */
  zipcode: string | null
  /** City */
  city: string | null
  /** State */
  state: string | null
  /** Customer Type */
  customer_type?: string | null
  /** Partner Id */
  partner_id: string | null
  /** Id */
  id: string
  /** Child Orgs */
  child_orgs?: OrganizationRead[] | null
}

/** Patient */
export interface Patient {
  /** Patient Name */
  patient_name: string
  /** Age */
  age?: number | null
  /** Sex */
  sex?: string | null
  /** Race */
  race?: string | null
  /** Primary Dx */
  primary_dx?: string | null
  /** Payer */
  payer?: string | null
  /** Initial Admission Date */
  initial_admission_date?: string | null
  /** Last Transfer Date */
  last_transfer_date?: string | null
}

/** PatientDaysAnalyticsItem */
export interface PatientDaysAnalyticsItem {
  /** Total Qm */
  total_qm: string[]
  /** Patient Days */
  patient_days: string[]
  /** Patient Days Per Qm */
  patient_days_per_qm: string[]
}

/** PatientDaysQueryParams */
export interface PatientDaysQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /**
   * Is Quarterly
   * @default false
   */
  is_quarterly?: boolean
  /**
   * Include Date Range In Title
   * @default false
   */
  include_date_range_in_title?: boolean
  /**
   * Filter 30 Days
   * @default true
   */
  filter_30_days?: boolean
  /** Payer Type */
  payer_type?: string[] | null
  /**
   * Include Hospice
   * @default true
   */
  include_hospice?: boolean
}

/** PatientDaysReportFilter */
export interface PatientDaysReportFilter {
  /** Payer Type */
  payer_type: string[]
}

/** PatientDaysReport[PatientDaysAnalyticsItem] */
export interface PatientDaysReportPatientDaysAnalyticsItem {
  organization: AnalyticsOrganizationPatientDaysAnalyticsItem
  /** Time Periods */
  time_periods: string[]
  filters: PatientDaysReportFilter
}

/** PatientOverview */
export interface PatientOverview {
  patient: Patient
  /** Recent Diagnoses */
  recent_diagnoses: CardContentItem[]
  /** Risk Conditions */
  risk_conditions: SaivaInternalSdkTherapyModelsRiskCondition[]
  /** Recent Orders */
  recent_orders: CardContentItem[]
  /** New Medications */
  new_medications: CardContentItem[]
  /** Labs */
  labs: SaivaInternalSdkTherapyModelsLabsData[]
  /** Vitals */
  vitals: VitalsData[]
}

/**
 * PatientRead
 * Represents a Patient response schema.
 */
export interface PatientRead {
  /** Mrn */
  mrn: string
  /** Saiva Patient Id */
  saiva_patient_id: string
  /** Patient Name */
  patient_name: string
  /** Room */
  room?: string | null
  /** Unit */
  unit?: string | null
  /** Due Date */
  due_date: string | null
  /** Screen Id */
  screen_id?: number | null
}

/** PatientScreenBlock */
export interface PatientScreenBlock {
  screen_type: ScreenType
  screen_status: ScreenStatus
  /** Screen Items */
  screen_items: ScreenRead[]
}

/** PatientScreenBlockWithMapping */
export interface PatientScreenBlockWithMapping {
  /** Email To Name Mapping */
  email_to_name_mapping: Record<string, string>
  screen_type: ScreenType
  screen_status: ScreenStatus
  /** Screen Items */
  screen_items: ScreenRead[]
}

/**
 * PatientScreenRead
 * Represents patients screens (completed or pending).
 */
export interface PatientScreenRead {
  /** Mrn */
  mrn: string
  /** Saiva Patient Id */
  saiva_patient_id: string
  /** Patient Name */
  patient_name: string
  /** Room */
  room?: string | null
  /** Unit */
  unit?: string | null
  /** Screen Types */
  screen_types: ScreenType[]
  /** Screen Id */
  screen_id: number
  action?: Action | null
  reason?: FailedReason | null
  /** Trigger Date */
  trigger_date?: string | null
  /**
   * Action Date
   * @format date-time
   */
  action_date: string
  /** Last Updated */
  last_updated?: string | null
  /** Rescreen Date */
  rescreen_date?: string | null
  /** Updated By */
  updated_by?: string | null
}

/** PatientScreensListWithMapping */
export interface PatientScreensListWithMapping {
  /** Email To Name Mapping */
  email_to_name_mapping: Record<string, string>
  /** Items */
  items: PatientScreenBlock[]
}

/** PatientSummary */
export interface PatientSummary {
  /** Patient Name */
  patient_name: string
  /** Masterpatientid */
  masterpatientid: string
  /** Room */
  room?: string | null
  /** Unit */
  unit?: string | null
  /** Floor */
  floor?: string | null
  /** Mrn */
  mrn: string
  /** Primary Dx */
  primary_dx?: string | null
  upt_risk?: RiskLevelEnum | null
  fall_risk?: RiskLevelEnum | null
  wound_risk?: RiskLevelEnum | null
}

/** PredictionsPerformanceAnalyticsItem */
export interface PredictionsPerformanceAnalyticsItem {
  /** Correct Predictions */
  correct_predictions: string[]
  /** Net Transfers */
  net_transfers: string[]
  /** Recall Rate */
  recall_rate: string[]
}

/** PredictionsPerformanceQueryParams */
export interface PredictionsPerformanceQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /**
   * Is Quarterly
   * @default false
   */
  is_quarterly?: boolean
  /**
   * Include Date Range In Title
   * @default false
   */
  include_date_range_in_title?: boolean
  /**
   * Filter 30 Days
   * @default true
   */
  filter_30_days?: boolean
  /** Planned */
  planned?: string[]
  /** Outcome */
  outcome?: string[]
}

/** PredictionsPerformanceReportFilter */
export interface PredictionsPerformanceReportFilter {
  /** Outcome */
  outcome: string[]
}

/** PredictionsPerformanceReport[PredictionsPerformanceAnalyticsItem] */
export interface PredictionsPerformanceReportPredictionsPerformanceAnalyticsItem {
  organization: AnalyticsOrganizationPredictionsPerformanceAnalyticsItem
  /** Time Periods */
  time_periods: string[]
  filters: PredictionsPerformanceReportFilter
  /** Charts */
  charts: ReportChartData[]
}

/** ProductFeature */
export interface ProductFeature {
  name: SaivaProductFeature
  /** Description */
  description?: string | null
}

/** ProductFeatureServiceRead */
export interface ProductFeatureServiceRead {
  name: SaivaProductFeature
  /** Description */
  description?: string | null
}

/** ProductList */
export interface ProductList {
  /** Items */
  items: SaivaProduct[]
}

/** ProductPackageCreate */
export interface ProductPackageCreate {
  /** Name */
  name: string
  /** Description */
  description: string
  /**
   * Is Addon
   * @default false
   */
  is_addon?: boolean
  /** Features */
  features: SaivaProductFeature[]
  /** @default "upt" */
  product_name?: SaivaProduct
}

/** ProductPackagePublicRead */
export interface ProductPackagePublicRead {
  /** Name */
  name: string
  /** Description */
  description?: string | null
  /** Is Addon */
  is_addon: boolean
}

/** ProductPackageRead */
export interface ProductPackageRead {
  /** Name */
  name: string
  /** Description */
  description: string
  /**
   * Is Addon
   * @default false
   */
  is_addon?: boolean
  /** Features */
  features: ProductFeature[]
  /** @default "upt" */
  product_name?: SaivaProduct
}

/** ProductPackageUpdate */
export interface ProductPackageUpdate {
  /** Name */
  name?: string | null
  /** Description */
  description?: string | null
  /**
   * Is Addon
   * @default false
   */
  is_addon?: boolean | null
  /** Features */
  features?: SaivaProductFeature[] | null
  product_name?: SaivaProduct | null
}

/** ProgressNoteTypesCreate */
export interface ProgressNoteTypesCreate {
  /** Progress Note Types */
  progress_note_types: string[]
}

/** ProgressNoteTypesRead */
export interface ProgressNoteTypesRead {
  /** Progress Note Types */
  progress_note_types: string[]
  /** Organization Id */
  organization_id: string
}

/** ProgressNoteTypesUpdate */
export interface ProgressNoteTypesUpdate {
  /** Progress Note Types */
  progress_note_types?: string[] | null
}

/** QualityMeasure */
export enum QualityMeasure {
  ModelRth = 'model_rth',
  ModelUpt = 'model_upt',
  ModelFall = 'model_fall',
  ModelWound = 'model_wound'
}

/** QuarterlyScreenAction */
export interface QuarterlyScreenAction {
  action: Action
  /** Screen Types */
  screen_types?: ScreenType[] | null
  /** Note */
  note?: string | null
}

/** QuarterlyScreenCreateOrUpdate */
export interface QuarterlyScreenCreateOrUpdate {
  /** Last Ard */
  last_ard: string
}

/** QuarterlyScreensWithMapping */
export interface QuarterlyScreensWithMapping {
  /** Email To Name Mapping */
  email_to_name_mapping: Record<string, string>
  /** Last Ard */
  last_ard?: string | null
  /** Due Date */
  due_date?: string | null
  /** Screen Id */
  screen_id?: number | null
  /** Previous Qs Screen Items */
  previous_qs_screen_items: PatientScreenBlock[]
}

/** RefreshTokenUpdate */
export interface RefreshTokenUpdate {
  /** Refresh Token */
  refresh_token: string
}

/** RegionAccessCreateRead */
export interface RegionAccessCreateRead {
  /** Full Access */
  full_access: boolean
  /** Items */
  items: RegionItemAccessRead[]
}

/** RegionCreate */
export interface RegionCreate {
  /** Name */
  name: string
  /** Facilities */
  facilities?: number[] | null
}

/** RegionItemAccessRead */
export interface RegionItemAccessRead {
  /** Region Id */
  region_id: number
  /** All Facilities */
  all_facilities: boolean
  /** Facility Ids */
  facility_ids: number[]
}

/** RegionRead */
export interface RegionRead {
  /** Id */
  id: number
  /** Name */
  name: string
  /** Facilities */
  facilities: FacilityNameRead[]
}

/** RegionServiceRead */
export interface RegionServiceRead {
  /** Id */
  id: number
  /** Name */
  name: string
}

/** RegionUpdate */
export interface RegionUpdate {
  /** Name */
  name: string
  /** Facilities */
  facilities?: number[] | null
}

/** RehospitalizationAnalyticsQueryParams */
export interface RehospitalizationAnalyticsQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /**
   * Is Quarterly
   * @default false
   */
  is_quarterly?: boolean
  /**
   * Include Date Range In Title
   * @default false
   */
  include_date_range_in_title?: boolean
  /**
   * Filter 30 Days
   * @default true
   */
  filter_30_days?: boolean
  /** Payer Type */
  payer_type?: string[]
  /** Outcome */
  outcome?: string[]
}

/** RehospitalizationReport */
export interface RehospitalizationReport {
  organization: RehospitalizationReportOrganization
  /** Time Periods */
  time_periods: string[]
  filters: RehospitalizationReportFilter
  /** Charts */
  charts: ReportChartData[]
}

/** RehospitalizationReportData */
export interface RehospitalizationReportData {
  /**
   * Transfers
   * @default []
   */
  Transfers?: string[]
  /**
   * Admissions
   * @default []
   */
  Admissions?: string[]
  /**
   * 30-Day Readmission Rate
   * @default []
   */
  '30-Day Readmission Rate'?: string[]
  /**
   * 30-Day Readmission Num/Den
   * @default []
   */
  '30-Day Readmission Num/Den'?: string[] | null
}

/** RehospitalizationReportFacility */
export interface RehospitalizationReportFacility {
  /** Id */
  id: string
  /** Name */
  name: string
  data: RehospitalizationReportData
}

/** RehospitalizationReportFilter */
export interface RehospitalizationReportFilter {
  /** Outcome */
  outcome: string[]
  /** Payer Type */
  payer_type: string[]
}

/** RehospitalizationReportOrganization */
export interface RehospitalizationReportOrganization {
  /** Id */
  id: string
  /** Name */
  name: string
  data: RehospitalizationReportData
  /** Regions */
  regions: RehospitalizationReportRegion[]
}

/** RehospitalizationReportRegion */
export interface RehospitalizationReportRegion {
  /** Id */
  id: string
  /** Name */
  name: string
  data: RehospitalizationReportData
  /** Facilities */
  facilities: RehospitalizationReportFacility[]
}

/** ReportCatalogRead */
export interface ReportCatalogRead {
  /** Id */
  id: string
  /** Label */
  label: string
  /** Report Types */
  report_types: ReportTypeStructureRead[]
}

/** ReportCategory */
export enum ReportCategory {
  PredictionPerformance = 'prediction_performance',
  AdverseEvents = 'adverse_events',
  ResidentInsight = 'resident_insight',
  Adoption = 'adoption'
}

/** ReportCategoryFilterParams */
export interface ReportCategoryFilterParams {
  /** Category */
  category?: ReportCategory[] | null
}

/** ReportChartData */
export interface ReportChartData {
  /** Title */
  title: string
  /** Datasets */
  datasets: ReportChartItemData[]
}

/** ReportChartItemData */
export interface ReportChartItemData {
  /** Label */
  label: string
  /** Data */
  data: number[]
}

/** ReportConfig */
export interface ReportConfig {
  report_type: AnalyticsReportTypeWithoutDaily
  /** Params */
  params: object
  report_category?: ReportCategory | null
}

/** ReportRead */
export interface ReportRead {
  /** Report Id */
  report_id: string
  /** Report Created Date */
  report_created_date?: string | null
  /** Report */
  report?: object | null
  /** Report Expiration Date */
  report_expiration_date?: string | null
}

/** ReportSortingOption */
export enum ReportSortingOption {
  Name = 'name',
  ValueName = '-name',
  ReportType = 'report_type',
  ValueReportType = '-report_type',
  FileFormat = 'file_format',
  ValueFileFormat = '-file_format',
  ScheduleType = 'schedule_type',
  ValueScheduleType = '-schedule_type',
  QualityMeasure = 'quality_measure',
  ValueQualityMeasure = '-quality_measure',
  AssigneeName = 'assignee_name',
  ValueAssigneeName = '-assignee_name',
  ScheduledByName = 'scheduled_by_name',
  ValueScheduledByName = '-scheduled_by_name'
}

/** ReportStructureFilterParams */
export interface ReportStructureFilterParams {
  /** Report Type */
  report_type?: AnalyticsReportType[] | null
}

/** ReportTypeStructureRead */
export interface ReportTypeStructureRead {
  /** Id */
  id: string
  /** Label */
  label: string
  /** Description */
  description: string
  /** Flag */
  flag?: string | null
  /** Reports */
  reports: object[]
}

/** Resident */
export interface Resident {
  /** Patient Id */
  patient_id: string
  /** Patient Name */
  patient_name: string
  /** Age */
  age?: number | null
  /** Sex */
  sex?: string | null
  /** Race */
  race?: string | null
  /** Primary Dx */
  primary_dx?: string | null
  /** Payer */
  payer?: string | null
  /** Initial Admission Date */
  initial_admission_date?: string | null
  /** Last Transfer Date */
  last_transfer_date?: string | null
}

/** ResidentInfo */
export interface ResidentInfo {
  /** Date */
  date?: string | null
  /** Patient Name */
  patient_name: string
  /** Facility Name */
  facility_name: string
  /** Room */
  room?: string | null
  /** Mrn */
  mrn: string
  /** Rank */
  rank?: number | null
}

/** ResidentOverview */
export interface ResidentOverview {
  resident_info: ResidentInfo
  resident: Resident
  /** Recent Diagnoses */
  recent_diagnoses: DateInfoValuePair[]
  /** Recent Medications */
  recent_medications: DateInfoValuePair[]
  /** Recent Orders */
  recent_orders: DateInfoValuePair[]
  /** Recent Labs */
  recent_labs: AppResidentModelsLabsData[]
  /** Risk Conditions */
  risk_conditions: AppResidentModelsRiskCondition[]
}

/** ResidentPage */
export interface ResidentPage {
  overview: ResidentOverview
  risk_analysis: RiskAnalysis
}

/** ResidentVisitRead */
export interface ResidentVisitRead {
  /** Visits */
  visits: ResidentVisitReadDetail[]
}

/** ResidentVisitReadDetail */
export interface ResidentVisitReadDetail {
  /** Masterpatientid */
  masterpatientid: number
  /** Is Visited */
  is_visited: boolean
}

/** ResidentVisitUpdate */
export interface ResidentVisitUpdate {
  /** Masterpatientid */
  masterpatientid: number
  /** Is Visited */
  is_visited: boolean
}

/** RiskAnalysis */
export interface RiskAnalysis {
  overview: RiskOverview
  /** Highlights */
  highlights: string[]
  keywords: Keywords
}

/** RiskConditionEnum */
export enum RiskConditionEnum {
  CHF = 'CHF',
  COPD = 'COPD',
  Pneumonia = 'Pneumonia',
  Cellulitis = 'Cellulitis',
  Sepsis = 'Sepsis',
  PressureWounds = 'Pressure Wounds',
  RenalDiseases = 'Renal Diseases',
  UTI = 'UTI',
  Diabetes = 'Diabetes',
  RespiratoryFailure = 'Respiratory failure',
  COVID19 = 'COVID-19'
}

/** RiskLevelEnum */
export enum RiskLevelEnum {
  Low = 'low',
  Medium = 'medium',
  High = 'high'
}

/** RiskOverview */
export interface RiskOverview {
  /** Current Rank */
  current_rank: number
  /** Current Rank Indicator */
  current_rank_indicator?: string | null
  /** Rank Yesterday */
  rank_yesterday?: number | null
  /** Risk Score */
  risk_score: number | string
}

/** RiskScoreChartPoint */
export interface RiskScoreChartPoint {
  /** Date */
  date?: string | null
  /** Risk Score */
  risk_score?: number | null
  /** Rank */
  rank?: number | null
}

/** RoleRead */
export interface RoleRead {
  name: SaivaRole
  /** Description */
  description?: string | null
  product_name: SaivaProduct
}

/** SaivaPermission */
export enum SaivaPermission {
  UsersRead = 'users_read',
  UsersEdit = 'users_edit',
  UsersDelete = 'users_delete',
  InvitationsRead = 'invitations_read',
  InvitationsCreate = 'invitations_create',
  InvitationsDelete = 'invitations_delete',
  InvitationsResend = 'invitations_resend',
  FacilitiesRead = 'facilities_read',
  FacilitiesReadAccessible = 'facilities_read_accessible',
  FacilitiesEdit = 'facilities_edit',
  RegionsCreate = 'regions_create',
  RegionsEdit = 'regions_edit',
  RegionsDelete = 'regions_delete',
  OrganizationsRead = 'organizations_read',
  OrganizationsEdit = 'organizations_edit',
  OrganizationBrandingReadWrite = 'organization_branding_read_write',
  VisitsRead = 'visits_read',
  VisitsEdit = 'visits_edit',
  ReportsRead = 'reports_read',
  AnalyticsRead = 'analytics_read',
  DashboardRead = 'dashboard_read',
  IntegrationsRead = 'integrations_read',
  IntegrationsConnect = 'integrations_connect',
  IntegrationsDelete = 'integrations_delete',
  IntegrationsUpdate = 'integrations_update',
  WoundCareRead = 'wound_care_read',
  WoundCareEdit = 'wound_care_edit',
  ScheduleForUserCreate = 'schedule_for_user_create',
  ScheduleForUserEdit = 'schedule_for_user_edit',
  ScheduleForUserRead = 'schedule_for_user_read',
  UserEngagementRead = 'user_engagement_read',
  TherapyDashboardRead = 'therapy_dashboard_read',
  TherapyScreensRead = 'therapy_screens_read',
  TherapyScreensCreate = 'therapy_screens_create',
  TherapyScreensEdit = 'therapy_screens_edit'
}

/** SaivaProduct */
export enum SaivaProduct {
  Upt = 'upt',
  WoundCare = 'wound_care',
  Therapy = 'therapy'
}

/** SaivaProductFeature */
export enum SaivaProductFeature {
  ReportingAnalytics = 'reporting_analytics',
  ReportingDailyRiskReport = 'reporting_daily_risk_report',
  ReportingUserEngagement = 'reporting_user_engagement',
  ModelRth = 'model_rth',
  ModelUpt = 'model_upt',
  ModelFall = 'model_fall',
  ModelWound = 'model_wound',
  WebhookIntegrations = 'webhook_integrations',
  SftpExportIntegration = 'sftp_export_integration',
  SdkApiIntegration = 'sdk_api_integration',
  MobileApp = 'mobile_app',
  OrganizationBranding = 'organization_branding',
  GenAi = 'gen_ai',
  UserLicenses = 'user_licenses',
  OnlineViewableReports = 'online_viewable_reports',
  WoundCare = 'wound_care',
  Therapy = 'therapy'
}

/** SaivaProductItemRead */
export interface SaivaProductItemRead {
  /** Role Name */
  role_name: string
  /** Product Name */
  product_name: string
}

/** SaivaRole */
export enum SaivaRole {
  UptUser = 'upt_user',
  UptAdmin = 'upt_admin',
  WoundCareUser = 'wound_care_user',
  WoundCareAdmin = 'wound_care_admin',
  TherapyAdmin = 'therapy_admin',
  TherapyRehabManager = 'therapy_rehab_manager',
  TherapyTherapist = 'therapy_therapist'
}

/** ScheduleConfig */
export interface ScheduleConfig {
  /** Day Of Week */
  day_of_week?: DayOfWeekOption[] | null
  /**
   * Time Of Day
   * Valid time of the day in 12 hour format
   * @pattern ^((1[0-2]|0?[1-9]):([0-4][0,5]) ?([AaPp][Mm]))$
   */
  time_of_day: string
  time_zone: TimeZoneOption
  schedule_type: ScheduleType
  /** Is Enabled */
  is_enabled: boolean
  file_format: FileFormatOption
  week_of_month?: WeekOfMonthOption | null
  /** Name */
  name: string
  /** User Id */
  user_id?: number | null
}

/** ScheduleConfigRead */
export interface ScheduleConfigRead {
  /** Day Of Week */
  day_of_week?: DayOfWeekOption[] | null
  /**
   * Time Of Day
   * Valid time of the day in 12 hour format
   * @pattern ^((1[0-2]|0?[1-9]):([0-4][0,5]) ?([AaPp][Mm]))$
   */
  time_of_day: string
  time_zone: TimeZoneOption
  schedule_type: ScheduleType
  /** Is Enabled */
  is_enabled: boolean
  file_format: FileFormatOption
  week_of_month?: WeekOfMonthOption | null
  /** Name */
  name: string
  /** User Id */
  user_id?: number | null
  /** Assignee Name */
  assignee_name: string
  /** Scheduled By Name */
  scheduled_by_name: string
}

/** ScheduleConfigUpdate */
export interface ScheduleConfigUpdate {
  /** Day Of Week */
  day_of_week?: DayOfWeekOption[] | null
  /**
   * Time Of Day
   * Valid time of the day in 12 hour format
   */
  time_of_day?: string | null
  time_zone?: TimeZoneOption | null
  schedule_type?: ScheduleType | null
  /** Is Enabled */
  is_enabled?: boolean | null
  file_format?: FileFormatOption | null
  week_of_month?: WeekOfMonthOption | null
  /** Name */
  name?: string | null
  /** User Id */
  user_id?: number | null
}

/** ScheduleType */
export enum ScheduleType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly'
}

/** ScheduledByUserData */
export interface ScheduledByUserData {
  /** Label */
  label: string
  /** Id */
  id: number
}

/** ScheduledByUsersList */
export interface ScheduledByUsersList {
  /** Scheduled By Users */
  scheduled_by_users: ScheduledByUserData[]
}

/** ScheduledReportCreate */
export interface ScheduledReportCreate {
  schedule_config: ScheduleConfig
  report_config: ReportConfig
}

/** ScheduledReportEmailData */
export interface ScheduledReportEmailData {
  /** Html */
  html: string
  /** File Path */
  file_path: string[]
  /** Recipient */
  recipient: string
}

/** ScheduledReportRead */
export interface ScheduledReportRead {
  schedule_config: ScheduleConfigRead
  report_config: ReportConfig
  /**
   * Id
   * @format uuid
   */
  id: string
}

/** ScheduledReportUpdate */
export interface ScheduledReportUpdate {
  schedule_config: ScheduleConfigUpdate
}

/** ScheduledReportsParams */
export interface ScheduledReportsParams {
  /** Q */
  q?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 1000
   * @default 50
   */
  size?: number
  /** File Format */
  file_format?: FileFormatOption[] | null
  /** Schedule Type */
  schedule_type?: ScheduleType[] | null
  /** Categories */
  categories?: ReportCategory[] | null
  /** Report Type */
  report_type?: AnalyticsReportTypeWithoutDaily[] | null
  /** Quality Measure */
  quality_measure?: QualityMeasure[] | null
  /** Show All */
  show_all?: boolean | null
  /** Scheduled By Id */
  scheduled_by_id?: number[] | null
  sort?: ReportSortingOption | null
}

/** ScopeOption */
export enum ScopeOption {
  ScopeDownloadDailyRiskReport = 'scope_download_daily_risk_report'
}

/** ScreenCreateOrUpdate */
export interface ScreenCreateOrUpdate {
  /** Trigger Ids */
  trigger_ids: number[]
  action: Action
  reason?: FailedReason | null
  /** Note */
  note?: string | null
  /** Rescreen At */
  rescreen_at?: string | null
}

/**
 * ScreenRead
 * Screens group with the triggers they are associated with
 */
export interface ScreenRead {
  /** Screen Id */
  screen_id?: number | null
  /** Action Items */
  action_items: ActionItem[]
  /** Trigger Items */
  trigger_items: TriggerItem[]
}

/** ScreenStatus */
export enum ScreenStatus {
  New = 'new',
  Pending = 'pending',
  Previous = 'previous',
  Completed = 'completed'
}

/** ScreenStatusFilter */
export enum ScreenStatusFilter {
  New = 'new',
  Pending = 'pending',
  Previous = 'previous'
}

/** ScreenType */
export enum ScreenType {
  PT = 'PT',
  OT = 'OT',
  ST = 'ST',
  QS = 'QS'
}

/** SdkApiIntegrationApiTokenRead */
export interface SdkApiIntegrationApiTokenRead {
  /** Name */
  name: string
  /** Scopes */
  scopes: ScopeOption[]
  /** Id */
  id: number
}

/** SdkApiIntegrationRead */
export interface SdkApiIntegrationRead {
  /** Id */
  id: number | null
  status: IntegrationStatusOption
  /** Created At */
  created_at?: string | null
  /** Created By */
  created_by?: number | null
  /** Organization Id */
  organization_id: string
  /**
   * Is Enabled
   * @default true
   */
  is_enabled?: boolean
  /**
   * Api Tokens
   * @default []
   */
  api_tokens?: SdkApiIntegrationApiTokenRead[]
}

/** SdkApiIntegrationUpdate */
export interface SdkApiIntegrationUpdate {
  /** Is Enabled */
  is_enabled?: boolean | null
}

/** SecretKeyRead */
export interface SecretKeyRead {
  /** Secret Key */
  secret_key: string
}

/** SendingReportType */
export enum SendingReportType {
  Attachment = 'attachment',
  ViewableOnline = 'viewable_online'
}

/** SftpExportActionStatus */
export interface SftpExportActionStatus {
  status?: ActionStatusOption | null
  action?: ActionOption | null
  /** Files Requested */
  files_requested?: number | null
  /** Files Uploaded */
  files_uploaded?: number | null
  /** Error Code */
  error_code?: string | null
}

/** SftpExportIntegrationAirflowRead */
export interface SftpExportIntegrationAirflowRead {
  /** @default "json" */
  file_format?: FileFormat
  /** Report Types */
  report_types: QualityMeasure[]
  /** Facility Ids */
  facility_ids: string[]
  /**
   * Risk List Length Ratio
   * @min 0
   * @max 1
   */
  risk_list_length_ratio: number
  /** Id */
  id: number | null
  status: IntegrationStatusOption
  /** Created At */
  created_at?: string | null
  /** Created By */
  created_by?: number | null
  /** Organization Id */
  organization_id: string
  /**
   * Is Enabled
   * @default true
   */
  is_enabled?: boolean
  /** S3 Bucket Name */
  s3_bucket_name: string
  /** Download Urls */
  download_urls: Record<string, Record<string, string | null>>
  /** @default "public_key" */
  auth_option?: AuthOption
}

/** SftpExportIntegrationCreate */
export interface SftpExportIntegrationCreate {
  /** @default "json" */
  file_format?: FileFormat
  /** Report Types */
  report_types: QualityMeasure[]
  /** Facility Ids */
  facility_ids: number[]
  /**
   * Risk List Length Ratio
   * @min 0
   * @max 1
   */
  risk_list_length_ratio: number
  /**
   * Public Key
   * Public key validation
   */
  public_key: string
}

/** SftpExportIntegrationRead */
export interface SftpExportIntegrationRead {
  /** @default "json" */
  file_format?: FileFormat
  /** Report Types */
  report_types: QualityMeasure[]
  /** Facility Ids */
  facility_ids: number[]
  /**
   * Risk List Length Ratio
   * @min 0
   * @max 1
   */
  risk_list_length_ratio: number
  /** Id */
  id: number | null
  status: IntegrationStatusOption
  /** Created At */
  created_at?: string | null
  /** Created By */
  created_by?: number | null
  /** Organization Id */
  organization_id: string
  /**
   * Is Enabled
   * @default true
   */
  is_enabled?: boolean
  /** @default "public_key" */
  auth_option?: AuthOption
  /** Region Ids */
  region_ids: number[]
}

/** SftpExportIntegrationUpdate */
export interface SftpExportIntegrationUpdate {
  /** Is Enabled */
  is_enabled?: boolean | null
  file_format?: FileFormat | null
  /** Report Types */
  report_types?: QualityMeasure[] | null
  /** Facility Ids */
  facility_ids?: number[] | null
  /**
   * Public Key
   * Public key validation
   */
  public_key?: string | null
  /** Risk List Length Ratio */
  risk_list_length_ratio?: number | null
}

/** SharingCreate */
export interface SharingCreate {
  /** Email */
  email: string | string[]
  /** Comment */
  comment?: string | null
}

/** SharingFeedBackConfig */
export interface SharingFeedBackConfig {
  /** Email */
  email: string
  /** Text */
  text: string
}

/** SharingUserRead */
export interface SharingUserRead {
  /** Email */
  email: string
  /** First Name */
  first_name: string
  /** Last Name */
  last_name: string
}

/** SourceCategory */
export enum SourceCategory {
  Diagnosis = 'diagnosis',
  Medication = 'medication',
  Order = 'order',
  ProgressNote = 'progress_note',
  QuarterlyScreening = 'quarterly_screening'
}

/** SupportedAppVersionRead */
export interface SupportedAppVersionRead {
  /** Min */
  min: string
  /** Max */
  max: string
  /** Update Url */
  update_url: string
}

/** TimeZoneOption */
export enum TimeZoneOption {
  USAlaska = 'US/Alaska',
  USAleutian = 'US/Aleutian',
  USArizona = 'US/Arizona',
  USCentral = 'US/Central',
  USEastIndiana = 'US/East-Indiana',
  USEastern = 'US/Eastern',
  USHawaii = 'US/Hawaii',
  USIndianaStarke = 'US/Indiana-Starke',
  USMichigan = 'US/Michigan',
  USMountain = 'US/Mountain',
  USPacific = 'US/Pacific',
  USSamoa = 'US/Samoa',
  UTC = 'UTC',
  GMT = 'GMT'
}

/** TriggerItem */
export interface TriggerItem {
  /** @default "trigger" */
  tag?: TriggerTag
  /**
   * Trigger Date
   * @format date-time
   */
  trigger_date: string
  trigger_source_category: SourceCategory
  /** Ui Summary */
  ui_summary?: string | null
  /** Trigger Id */
  trigger_id: number
}

/** TriggerTag */
export enum TriggerTag {
  New = 'new',
  Trigger = 'trigger',
  Expired = 'expired'
}

/**
 * UnscreenedPatientRead
 * Represents a Unscreened Patients response schema.
 */
export interface UnscreenedPatientRead {
  /** Mrn */
  mrn: string
  /** Saiva Patient Id */
  saiva_patient_id: string
  /** Patient Name */
  patient_name: string
  /** Room */
  room?: string | null
  /** Unit */
  unit?: string | null
  /**
   * Trigger Date
   * @format date-time
   */
  trigger_date: string
  /** Screen Types */
  screen_types: ScreenType[]
}

/** UrlValueField */
export interface UrlValueField {
  /** Value */
  value: string
  /** Detail Url */
  detail_url?: string | null
}

/** UserCreate */
export interface UserCreate {
  /** Email */
  email?: string | null
  /** Title Id */
  title_id?: string | null
  /** Name */
  name?: string | null
  role?: SaivaRole | null
  /** Enabled */
  enabled?: boolean | null
  regions?: RegionAccessCreateRead | null
  /** Capabilities */
  capabilities?: Capability[] | null
  /**
   * Is Superuser
   * @default false
   */
  is_superuser?: boolean
  /** Parent Org Id */
  parent_org_id?: string | null
  /** Password */
  password?: string | null
  /**
   * Is Active
   * @default true
   */
  is_active?: boolean
}

/** UserEngagement */
export interface UserEngagement {
  /** Rows */
  rows: UserEngagementRows[]
  filters: UserEngagementFilter
}

/** UserEngagementFacilityRow */
export interface UserEngagementFacilityRow {
  /** Facility Name */
  facility_name: string
  /**
   * Date
   * @format date
   */
  date: string
  /** Saiva Web */
  saiva_web: number | string
  /** Saiva Mobile */
  saiva_mobile: number | string
  /** Saiva Email */
  saiva_email: number | string
}

/** UserEngagementFacilityView */
export interface UserEngagementFacilityView {
  /** Rows */
  rows: UserEngagementFacilityViewRow[]
  filters: UserEngagementFilter
}

/** UserEngagementFacilityViewQueryParams */
export interface UserEngagementFacilityViewQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /**
   * Event Types
   * @default ["saiva_mobile","saiva_web","saiva_email"]
   */
  event_types?: string[] | null
  /** @default "facility_name" */
  sort?: UserEngagementFacilityViewSortingOption
  /**
   * Q
   * @default ""
   */
  q?: string
  /** Hidden Columns */
  hidden_columns?: string[]
}

/** UserEngagementFacilityViewRow */
export interface UserEngagementFacilityViewRow {
  /** Facility Name */
  facility_name: string
  /** Region Name */
  region_name: string
  /** Saiva Web */
  saiva_web: number | string
  /** Saiva Mobile */
  saiva_mobile: number | string
  /** Saiva Email */
  saiva_email: number | string
}

/** UserEngagementFacilityViewSortingOption */
export enum UserEngagementFacilityViewSortingOption {
  FacilityName = 'facility_name',
  ValueFacilityName = '-facility_name',
  RegionName = 'region_name',
  ValueRegionName = '-region_name',
  SaivaWeb = 'saiva_web',
  ValueSaivaWeb = '-saiva_web',
  SaivaMobile = 'saiva_mobile',
  ValueSaivaMobile = '-saiva_mobile',
  SaivaEmail = 'saiva_email',
  ValueSaivaEmail = '-saiva_email'
}

/** UserEngagementFilter */
export interface UserEngagementFilter {
  /**
   * Event Types
   * @default [{"id":"saiva_mobile","label":"Mobile App"},{"id":"saiva_web","label":"Web App"},{"id":"saiva_email","label":"Email Link"}]
   */
  event_types?: object[]
  /**
   * Roles
   * @default ["User","Admin","Unspecified"]
   */
  roles?: string[]
  /** Titles */
  titles: string[]
}

/** UserEngagementQueryParams */
export interface UserEngagementQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /**
   * Event Types
   * @default ["saiva_mobile","saiva_web","saiva_email"]
   */
  event_types?: string[] | null
  /** Roles */
  roles?: string[] | null
  /** Titles */
  titles?: string[] | null
  /** @default "name" */
  sort?: UserEngagementSortingOption
  /**
   * Q
   * @default ""
   */
  q?: string
  /** Hidden Columns */
  hidden_columns?: string[]
}

/** UserEngagementRows */
export interface UserEngagementRows {
  /** Name */
  name: string
  /** Email */
  email: string
  /** Role */
  role: string
  /** Title */
  title: string
  /** Facility Name */
  facility_name: string
  /**
   * Date
   * @format date
   */
  date: string
  /** Facility Data */
  facility_data: UserEngagementFacilityRow[]
  /** Saiva Web */
  saiva_web: number | string
  /** Saiva Mobile */
  saiva_mobile: number | string
  /** Saiva Email */
  saiva_email: number | string
}

/** UserEngagementSortingOption */
export enum UserEngagementSortingOption {
  Name = 'name',
  ValueName = '-name',
  Email = 'email',
  ValueEmail = '-email',
  Role = 'role',
  ValueRole = '-role',
  Title = 'title',
  ValueTitle = '-title',
  Date = 'date',
  ValueDate = '-date',
  FacilityName = 'facility_name',
  ValueFacilityName = '-facility_name',
  SaivaWeb = 'saiva_web',
  ValueSaivaWeb = '-saiva_web',
  SaivaMobile = 'saiva_mobile',
  ValueSaivaMobile = '-saiva_mobile',
  SaivaEmail = 'saiva_email',
  ValueSaivaEmail = '-saiva_email'
}

/** UserFirebaseTokenRead */
export interface UserFirebaseTokenRead {
  /** Email */
  email: string
  /** Token */
  token: string
  product_name: SaivaProduct
}

/** UserFirebaseTokenReadMultiple */
export interface UserFirebaseTokenReadMultiple {
  /** Items */
  items: UserFirebaseTokenRead[]
}

/** UserFirebaseTokenUpdate */
export interface UserFirebaseTokenUpdate {
  /** Device Id */
  device_id: string
  /** Token */
  token: string
  /**
   * Mobile Version
   * @default 170
   */
  mobile_version?: number
}

/** UserMeOrganizationDetailsRead */
export interface UserMeOrganizationDetailsRead {
  /** Id */
  id: string
  /** Name */
  name: string
  status: OrganizationStatus
  /** Partner Id */
  partner_id: string | null
  /** User Title */
  user_title: string
  /** User Role */
  user_role: string
  /**
   * Permissions
   * @default []
   */
  permissions?: SaivaPermission[]
  /**
   * Product Features
   * @default []
   */
  product_features?: string[]
}

/** UserMeOrgsRead */
export interface UserMeOrgsRead {
  /** Orgs */
  orgs: UserOrgRead[]
}

/** UserMeRead */
export interface UserMeRead {
  /** Id */
  id: number
  /**
   * Email
   * @format email
   */
  email: string
  /** Name */
  name: string
  /** Is Superuser */
  is_superuser: boolean
  /** Product List */
  product_list: SaivaProduct[]
}

/** UserMeUpdate */
export interface UserMeUpdate {
  /** Title */
  title?: string | null
  /** Name */
  name?: string | null
}

/** UserOrgRead */
export interface UserOrgRead {
  /** Id */
  id: string
  /** Name */
  name: string
  /** Partner Id */
  partner_id: string | null
}

/** UserRead */
export interface UserRead {
  /** Id */
  id: number
  /**
   * Email
   * @format email
   */
  email: string
  /** Name */
  name: string
  /** Title Id */
  title_id: string
  role: SaivaRole
  /**
   * Enabled
   * @default true
   */
  enabled?: boolean
  /** Is Superuser */
  is_superuser: boolean
  regions: RegionAccessCreateRead
  /** Capabilities */
  capabilities: Capability[]
  /** Last Active At */
  last_active_at: string | null
}

/** UserRoleBase */
export interface UserRoleBase {
  role_name: SaivaRole
  /**
   * Enabled
   * @default true
   */
  enabled?: boolean
}

/** UserServiceRead */
export interface UserServiceRead {
  /** Id */
  id: number
  /** Name */
  name: string
  /** Email */
  email: string
}

/** UserServiceReadMultiple */
export interface UserServiceReadMultiple {
  /** Items */
  items: UserServiceRead[]
}

/** UserSortOption */
export enum UserSortOption {
  Name = 'name',
  ValueName = '-name',
  Email = 'email',
  ValueEmail = '-email',
  TitleId = 'title_id',
  ValueTitleId = '-title_id',
  LastActiveAt = 'last_active_at',
  ValueLastActiveAt = '-last_active_at'
}

/** UserTitleRead */
export interface UserTitleRead {
  /** Id */
  id: string
  /** Name */
  name: string
}

/** UserUpdate */
export interface UserUpdate {
  /** Email */
  email?: string | null
  /** Title Id */
  title_id?: string | null
  /** Name */
  name?: string | null
  role?: SaivaRole | null
  /** Enabled */
  enabled?: boolean | null
  regions?: RegionAccessCreateRead | null
  /** Capabilities */
  capabilities?: Capability[] | null
  /**
   * Is Superuser
   * @default false
   */
  is_superuser?: boolean
  /** Parent Org Id */
  parent_org_id?: string | null
}

/** V1LoginTokens */
export interface V1LoginTokens {
  /** Access */
  access: string
  /** Refresh */
  refresh: string
}

/** V1OtpLogin */
export interface V1OtpLogin {
  /**
   * Username
   * @format email
   */
  username: string
  /** Phrase */
  phrase?: string | null
}

/** V1OtpLoginWithPassword */
export interface V1OtpLoginWithPassword {
  /**
   * Username
   * @format email
   */
  username: string
  /** Phrase */
  phrase?: string | null
  /** Password */
  password: string
}

/** V1RefreshTokenUpdate */
export interface V1RefreshTokenUpdate {
  /** Refresh */
  refresh: string
}

/** V1UserFirebaseTokenUpdate */
export interface V1UserFirebaseTokenUpdate {
  /** Device Id */
  device_id: string
  /** Fcm Token */
  fcm_token: string
  /**
   * Mobile Version
   * @default 170
   */
  mobile_version?: number
}

/** V1UserMeRead */
export interface V1UserMeRead {
  /** Username */
  username: string
  /** Access List */
  access_list: V1UserMeReadOrganization[]
}

/** V1UserMeReadFacility */
export interface V1UserMeReadFacility {
  /** Facility Id */
  facility_id: string
  /** Name */
  name: string
}

/** V1UserMeReadOrganization */
export interface V1UserMeReadOrganization {
  /** Org Id */
  org_id: string
  /** Name */
  name: string
  /** Facilities */
  facilities: V1UserMeReadFacility[]
}

/** V2InvitationCreate */
export interface V2InvitationCreate {
  /**
   * Email
   * @format email
   */
  email: string
  /** Title */
  title: string
  /** Name */
  name: string
  /** Roles */
  roles: UserRoleBase[]
  regions: RegionAccessCreateRead
  /** Capabilities */
  capabilities: Capability[]
}

/** V2InvitationRead */
export interface V2InvitationRead {
  /**
   * Email
   * @format email
   */
  email: string
  /** Title */
  title: string
  /** Name */
  name: string
  /** Roles */
  roles: UserRoleBase[]
  regions: RegionAccessCreateRead
  /** Capabilities */
  capabilities: Capability[]
  /** Id */
  id: number
  /**
   * Created At
   * @format date-time
   */
  created_at: string
  /**
   * Expires At
   * @format date-time
   */
  expires_at: string
  /** Invited By */
  invited_by: string | null
  status: InvitationStatus
}

/** V2InvitationUpdate */
export interface V2InvitationUpdate {
  /**
   * Email
   * @format email
   */
  email: string
  /** Title */
  title: string
  /** Name */
  name: string
  /** Roles */
  roles: UserRoleBase[]
  regions: RegionAccessCreateRead
  /** Capabilities */
  capabilities: Capability[]
}

/** V2LegacyUserOrganizationAccessRead */
export interface V2LegacyUserOrganizationAccessRead {
  /** Id */
  id: string
  /** Name */
  name: string
  /** Partner Id */
  partner_id: string | null
  /** Roles */
  roles: SaivaProductItemRead[]
  /**
   * Permissions
   * @default []
   */
  permissions?: SaivaPermission[]
  /**
   * Product Features
   * @default []
   */
  product_features?: string[]
}

/** V2LegacyUserReadWithOrgRoles */
export interface V2LegacyUserReadWithOrgRoles {
  /** Id */
  id: number
  /**
   * Email
   * @format email
   */
  email: string
  /** Title */
  title: string
  /** Product Name */
  product_name: SaivaProduct[]
  /** Name */
  name: string
  /** Is Superuser */
  is_superuser: boolean
  /** Orgs */
  orgs: V2LegacyUserOrganizationAccessRead[]
}

/** V2ListInvitationsParams */
export interface V2ListInvitationsParams {
  /** Q */
  q?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 1000
   * @default 50
   */
  size?: number
  /** @default "email" */
  sort?: InvitationSortOption | null
  /** @default "upt" */
  product_name?: SaivaProduct | null
}

/** V2ListUsersParams */
export interface V2ListUsersParams {
  /** Q */
  q?: string | null
  /** Active */
  active?: boolean | null
  /** Enabled */
  enabled?: boolean | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 1000
   * @default 50
   */
  size?: number
  /** @default "name" */
  sort?: V2UserSortOption | null
  /** Roles */
  roles?: SaivaRole[] | null
  /** Facility Ids */
  facility_ids?: number[] | null
  /** Region Ids */
  region_ids?: number[] | null
  /** Capabilities */
  capabilities?: Capability[] | null
  /** @default "upt" */
  product_name?: SaivaProduct | null
}

/** V2UserCreate */
export interface V2UserCreate {
  /** Email */
  email?: string | null
  /** Title */
  title?: string | null
  /** Name */
  name?: string | null
  /** Roles */
  roles?: UserRoleBase[] | null
  regions?: RegionAccessCreateRead | null
  /** Capabilities */
  capabilities?: Capability[] | null
  /**
   * Is Superuser
   * @default false
   */
  is_superuser?: boolean
  /** Medical Supply Org Id */
  medical_supply_org_id?: string | null
  /** Password */
  password?: string | null
  /**
   * Is Active
   * @default true
   */
  is_active?: boolean
}

/** V2UserRead */
export interface V2UserRead {
  /** Id */
  id: number
  /**
   * Email
   * @format email
   */
  email: string
  /** Title */
  title: string
  /** Name */
  name: string
  /** Is Superuser */
  is_superuser: boolean
  /** Roles */
  roles: UserRoleBase[]
  regions: RegionAccessCreateRead
  /** Capabilities */
  capabilities: Capability[]
  /** Last Active At */
  last_active_at?: string | null
}

/** V2UserSortOption */
export enum V2UserSortOption {
  Name = 'name',
  ValueName = '-name',
  Email = 'email',
  ValueEmail = '-email',
  Title = 'title',
  ValueTitle = '-title',
  LastActiveAt = 'last_active_at',
  ValueLastActiveAt = '-last_active_at'
}

/** V2UserUpdate */
export interface V2UserUpdate {
  /** Email */
  email?: string | null
  /** Title */
  title?: string | null
  /** Name */
  name?: string | null
  /** Roles */
  roles?: UserRoleBase[] | null
  regions?: RegionAccessCreateRead | null
  /** Capabilities */
  capabilities?: Capability[] | null
  /**
   * Is Superuser
   * @default false
   */
  is_superuser?: boolean
  /** Medical Supply Org Id */
  medical_supply_org_id?: string | null
}

/** ValidFilterValues */
export interface ValidFilterValues {
  /** Values */
  values: (string | null)[]
}

/** ValidFiltersRead */
export interface ValidFiltersRead {
  /** Values */
  values: (string | null)[]
  filter_type: FilterType
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/** ViewedReports */
export interface ViewedReports {
  /** Rows */
  rows: ViewedReportsUserRows[]
  /** Stats */
  stats: ViewedReportsStats[]
  filters: ViewedReportsFilter
}

/** ViewedReportsFacilityView */
export interface ViewedReportsFacilityView {
  /** Rows */
  rows: ViewedReportsFacilityViewRow[]
  filters: ViewedReportsFilter
}

/** ViewedReportsFacilityViewQueryParams */
export interface ViewedReportsFacilityViewQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /**
   * Report Types
   * @default ["daily_risk_report","cross_facility_risk_report"]
   */
  report_types?: AnalyticsReportType[] | null
  /**
   * Q
   * @default ""
   */
  q?: string
  /** Hidden Columns */
  hidden_columns?: string[]
  /** @default "facility_name" */
  sort?: ViewedReportsFacilityViewSortingOption
}

/** ViewedReportsFacilityViewRow */
export interface ViewedReportsFacilityViewRow {
  /** Facility Name */
  facility_name: string
  /** Region Name */
  region_name: string
  /**
   * Daily Risk Report Viewed Count
   * @default 0
   */
  daily_risk_report_viewed_count?: number | string
  /**
   * Cross Facility Risk Report Viewed Count
   * @default 0
   */
  cross_facility_risk_report_viewed_count?: number | string
}

/** ViewedReportsFacilityViewSortingOption */
export enum ViewedReportsFacilityViewSortingOption {
  FacilityName = 'facility_name',
  ValueFacilityName = '-facility_name',
  RegionName = 'region_name',
  ValueRegionName = '-region_name',
  DailyRiskReportViewedCount = 'daily_risk_report_viewed_count',
  ValueDailyRiskReportViewedCount = '-daily_risk_report_viewed_count',
  CrossFacilityRiskReportViewedCount = 'cross_facility_risk_report_viewed_count',
  ValueCrossFacilityRiskReportViewedCount = '-cross_facility_risk_report_viewed_count'
}

/** ViewedReportsFilter */
export interface ViewedReportsFilter {
  /**
   * Report Types
   * @default [{"id":"daily_risk_report","label":"Daily Risk"},{"id":"cross_facility_risk_report","label":"Cross-Facility Risk"}]
   */
  report_types?: object[]
  /**
   * Roles
   * @default ["User","Admin","Unspecified"]
   */
  roles?: string[]
  /** Titles */
  titles: string[]
}

/** ViewedReportsQueryParams */
export interface ViewedReportsQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /**
   * Report Types
   * @default ["daily_risk_report","cross_facility_risk_report"]
   */
  report_types?: AnalyticsReportType[] | null
  /** Roles */
  roles?: string[] | null
  /** Titles */
  titles?: string[] | null
  /** @default "name" */
  sort?: ViewedReportsSortingOption
  /**
   * Q
   * @default ""
   */
  q?: string
  /** Hidden Columns */
  hidden_columns?: string[]
}

/** ViewedReportsRow */
export interface ViewedReportsRow {
  /** Facility Name */
  facility_name: string
  /**
   * Date
   * @format date
   */
  date: string
  /** Daily Risk Report Viewed Count */
  daily_risk_report_viewed_count: number | string
  /** Cross Facility Risk Report Viewed Count */
  cross_facility_risk_report_viewed_count: number | string
}

/** ViewedReportsSortingOption */
export enum ViewedReportsSortingOption {
  Name = 'name',
  ValueName = '-name',
  Email = 'email',
  ValueEmail = '-email',
  Role = 'role',
  ValueRole = '-role',
  Title = 'title',
  ValueTitle = '-title',
  Date = 'date',
  ValueDate = '-date',
  FacilityName = 'facility_name',
  ValueFacilityName = '-facility_name',
  DailyRiskReportViewedCount = 'daily_risk_report_viewed_count',
  ValueDailyRiskReportViewedCount = '-daily_risk_report_viewed_count',
  CrossFacilityRiskReportViewedCount = 'cross_facility_risk_report_viewed_count',
  ValueCrossFacilityRiskReportViewedCount = '-cross_facility_risk_report_viewed_count'
}

/** ViewedReportsStats */
export interface ViewedReportsStats {
  /** Id */
  id: string
  /** Label */
  label: string
  /** Count */
  count: number
  /** Data */
  data: ActiveUsersWidgetData[]
}

/** ViewedReportsUserRows */
export interface ViewedReportsUserRows {
  /** Name */
  name: string
  /** Email */
  email: string
  /** Role */
  role: string
  /** Title */
  title: string
  /** Facility Name */
  facility_name: string
  /**
   * Date
   * @format date
   */
  date: string
  /** Facility Data */
  facility_data?: ViewedReportsRow[] | null
  /**
   * Daily Risk Report Viewed Count
   * @default 0
   */
  daily_risk_report_viewed_count?: number | string
  /**
   * Cross Facility Risk Report Viewed Count
   * @default 0
   */
  cross_facility_risk_report_viewed_count?: number | string
}

/** VisitsReport */
export interface VisitsReport {
  /** Facilities */
  facilities: VisitsReportItem[]
  stats?: VisitsReportStats | null
}

/** VisitsReportItem */
export interface VisitsReportItem {
  /** Id */
  id: number
  /** Name */
  name: string
  /** Region Name */
  region_name: string
  /** Data */
  data: VisitsReportItemData[]
}

/** VisitsReportItemData */
export interface VisitsReportItemData {
  /** Datetime Visited */
  datetime_visited?: string | null
  /** User */
  user?: string | null
  /** Mrn */
  mrn?: string | null
  resident?: UrlValueField | null
  /** Rank */
  rank?: number | string | null
  /** Rank Date */
  rank_date?: string | null
}

/** VisitsReportQueryParams */
export interface VisitsReportQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
}

/** VisitsReportStats */
export interface VisitsReportStats {
  /**
   * Unique Patients
   * @format integer
   */
  unique_patients: string
  /**
   * Patients Visited
   * @format integer
   */
  patients_visited: string
  /**
   * All Visits
   * @format integer
   */
  all_visits: string
  /**
   * Coverage
   * @pattern string
   */
  coverage: string
  /**
   * Coverage Unique
   * @pattern string
   */
  coverage_unique: string
}

/** VitalsData */
export interface VitalsData {
  /** Datetime */
  datetime: string | null
  /** Description */
  description?: string | null
  /** Value */
  value?: string | null
  /**
   * Is Critical
   * @default false
   */
  is_critical?: boolean
}

/** VitalsReport */
export interface VitalsReport {
  /** Facilities */
  facilities: VitalsReportItem[]
}

/** VitalsReportItem */
export interface VitalsReportItem {
  /** Id */
  id: number
  /** Name */
  name: string
  /** Region Name */
  region_name: string
  /** Data */
  data: VitalsReportItemData[]
}

/** VitalsReportItemData */
export interface VitalsReportItemData {
  /** Mrn */
  mrn: string
  resident: UrlValueField
  /** Rank */
  rank: number
  /**
   * Date
   * @format date
   */
  date: string
  /** Abnormal Vitals */
  abnormal_vitals: VitalsReportItemDataVitals[]
  /** @default "No" */
  covid_diagnosed?: CovidDiagnosedValues
}

/** VitalsReportItemDataVitals */
export interface VitalsReportItemDataVitals {
  /** Value */
  value: string
  /** Unit */
  unit: string
  /** Name */
  name: string
}

/** VitalsReportQueryParams */
export interface VitalsReportQueryParams {
  /** Facility Ids */
  facility_ids?: number[]
  /**
   * Date Start
   * @format date
   */
  date_start: string
  /**
   * Date End
   * @format date
   */
  date_end: string
  /**
   * Quality Measure
   * @default ""
   */
  quality_measure?: QualityMeasure | string
  /** Covid Diagnosed */
  covid_diagnosed?: CovidDiagnosedValues[]
}

/** WebhookCrossFacilityDailyRiskReport */
export interface WebhookCrossFacilityDailyRiskReport {
  /** Items */
  items?: WebhookCrossFacilityDailyRiskReportRecord[] | null
}

/** WebhookCrossFacilityDailyRiskReportRecord */
export interface WebhookCrossFacilityDailyRiskReportRecord {
  /** Facility Name */
  facility_name: string
  /** Region Name */
  region_name: string
  /** Mrn */
  mrn: string
  /** Unit */
  unit?: string | null
  /** Room */
  room?: string | null
  /** Rank Today */
  rank_today?: number | string | null
  /** Rank Yesterday */
  rank_yesterday?: number | string | null
  /** Rank Movement */
  rank_movement?: number | string | null
  /** Payer */
  payer?: string | null
  /** Physician */
  physician?: string | null
  /** Initial Admission Date */
  initial_admission_date?: string | null
  /** Last Transfer Date */
  last_transfer_date?: string | null
  /**
   * Visited
   * @default ""
   */
  visited?: string
}

/** WebhookIntegrationEndpointCreate */
export interface WebhookIntegrationEndpointCreate {
  /** Name */
  name: string
  /**
   * Client Endpoint Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  client_endpoint_url: string
  report_type: WebhookReportType
  quality_measure: QualityMeasure
  /** Facility Ids */
  facility_ids: number[]
}

/** WebhookIntegrationEndpointRead */
export interface WebhookIntegrationEndpointRead {
  /** Name */
  name: string
  /**
   * Client Endpoint Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  client_endpoint_url: string
  report_type: WebhookReportType
  quality_measure: QualityMeasure
  /** Facility Ids */
  facility_ids: number[]
  /**
   * Id
   * @format uuid
   */
  id: string
  status: IntegrationStatusOption
  /** Secret Key */
  secret_key: string
  /**
   * Is Disabled Due To Error
   * @default false
   */
  is_disabled_due_to_error?: boolean
}

/** WebhookIntegrationEndpointReadWithSecretKey */
export interface WebhookIntegrationEndpointReadWithSecretKey {
  /** Secret Key */
  secret_key: string
  /** Name */
  name: string
  /**
   * Client Endpoint Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  client_endpoint_url: string
  report_type: WebhookReportType
  quality_measure: QualityMeasure
  /** Facility Ids */
  facility_ids: number[]
  /**
   * Id
   * @format uuid
   */
  id: string
  status: IntegrationStatusOption
}

/** WebhookIntegrationEndpointServiceRead */
export interface WebhookIntegrationEndpointServiceRead {
  /** Webhook Name */
  webhook_name: string
  /** Webhook Id */
  webhook_id: string
  /** Client Endpoint Url */
  client_endpoint_url: string
  report_type: WebhookReportType
  quality_measure: QualityMeasure
  /** Facility Ids */
  facility_ids: string[]
  /** Callback Url */
  callback_url: string
  /** Secret Key Path */
  secret_key_path: string
  status: IntegrationStatusOption
  /** Download Urls */
  download_urls: Record<string, Record<string, string | null>>
}

/** WebhookIntegrationEndpointTestResult */
export interface WebhookIntegrationEndpointTestResult {
  /**
   * Webhook Id
   * @format uuid
   */
  webhook_id: string
  status: WebhookStatusExternal
  error?: ErrorMessage | null
  /**
   * Is Scheduled
   * @default false
   */
  is_scheduled?: boolean
  /**
   * Retry Attempt
   * @default 1
   */
  retry_attempt?: number
  /**
   * Is Test
   * @default false
   */
  is_test?: boolean
  /**
   * Is Ping
   * @default false
   */
  is_ping?: boolean
}

/** WebhookIntegrationEndpointUpdate */
export interface WebhookIntegrationEndpointUpdate {
  /** Status */
  status?: 'connected' | 'disconnected' | null
  /** Name */
  name?: string | null
  /** Client Endpoint Url */
  client_endpoint_url?: string | null
  report_type?: WebhookReportType | null
  quality_measure?: QualityMeasure | null
  /** Facility Ids */
  facility_ids?: number[] | null
}

/** WebhookIntegrationEndpointWithRegionsRead */
export interface WebhookIntegrationEndpointWithRegionsRead {
  /** Name */
  name: string
  /**
   * Client Endpoint Url
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  client_endpoint_url: string
  report_type: WebhookReportType
  quality_measure: QualityMeasure
  /** Facility Ids */
  facility_ids: number[]
  /**
   * Id
   * @format uuid
   */
  id: string
  status: IntegrationStatusOption
  /** Secret Key */
  secret_key: string
  /**
   * Is Disabled Due To Error
   * @default false
   */
  is_disabled_due_to_error?: boolean
  /** Region Ids */
  region_ids?: number[] | null
}

/** WebhookIntegrationRead */
export interface WebhookIntegrationRead {
  /** Id */
  id: number | null
  status: IntegrationStatusOption
  /** Created At */
  created_at?: string | null
  /** Created By */
  created_by?: number | null
  /** Organization Id */
  organization_id: string
  /**
   * Is Enabled
   * @default true
   */
  is_enabled?: boolean
  /**
   * Endpoints
   * @default []
   */
  endpoints?: WebhookIntegrationEndpointWithRegionsRead[]
}

/** WebhookIntegrationServiceRead */
export interface WebhookIntegrationServiceRead {
  /** Integration Enabled */
  integration_enabled: boolean
  /** Endpoints */
  endpoints: WebhookIntegrationEndpointServiceRead[]
}

/** WebhookIntegrationUpdate */
export interface WebhookIntegrationUpdate {
  /** Is Enabled */
  is_enabled?: boolean | null
}

/** WebhookReportType */
export enum WebhookReportType {
  DailyRiskReport = 'daily_risk_report',
  CrossFacilityRiskReport = 'cross_facility_risk_report'
}

/** WebhookStatusExternal */
export enum WebhookStatusExternal {
  OK = 'OK',
  Failed = 'failed',
  Retrying = 'retrying'
}

/** WeekOfMonthOption */
export enum WeekOfMonthOption {
  First = 'first',
  Last = 'last'
}

/** WoundCareAction */
export enum WoundCareAction {
  OnCaseload = 'On Caseload',
  PayerIneligible = 'Payer Ineligible',
  OrderIneligible = 'Order Ineligible',
  MissingDoc = 'Missing Doc.'
}

/** WoundCareConfigServiceRead */
export interface WoundCareConfigServiceRead {
  /** Organization Id */
  organization_id: string
  /** Data Source Org Id */
  data_source_org_id: string
  /** Data Source Id */
  data_source_id: string
}

/** WoundCareDocumentComponentCategory */
export interface WoundCareDocumentComponentCategory {
  /** Category Name */
  category_name: string
  /** Items */
  items: AppWoundCareModelsWoundCareDocumentComponentItemRead[]
  /** Total */
  total: number
  /** Obtain */
  obtain: number
  missing_doc_status: WoundCareMissingDocStatus
}

/** WoundCareDocumentComponentCreate */
export interface WoundCareDocumentComponentCreate {
  /** Components */
  components: WoundCareDocumentComponentItemCreate[]
  /** Org Id */
  org_id?: string | null
}

/** WoundCareDocumentComponentItemCreate */
export interface WoundCareDocumentComponentItemCreate {
  order_type: WoundCareOrderType
  /** Document Category */
  document_category: string
  /** Component Name */
  component_name: string
  /** Is Required */
  is_required?: boolean | null
}

/** WoundCareDocumentComponentRead */
export interface WoundCareDocumentComponentRead {
  /** Components */
  components: AppInternalWoundCareModelsWoundCareDocumentComponentItemRead[]
}

/** WoundCareDocumentComponentReadByCategories */
export interface WoundCareDocumentComponentReadByCategories {
  /** Components */
  components: WoundCareDocumentComponentCategory[]
  /** Total */
  total: number
  /** Obtain */
  obtain: number
  /** Updated At */
  updated_at?: string | null
  /** Updated By */
  updated_by?: string | null
}

/** WoundCareDocumentComponentUpdate */
export interface WoundCareDocumentComponentUpdate {
  /** Components */
  components: AppWoundCareModelsWoundCareDocumentComponentItemUpdate[]
}

/** WoundCareFilter */
export interface WoundCareFilter {
  /** Payers */
  payers: string[]
  /** Facility States */
  facility_states: string[]
}

/** WoundCareMissingDocStatus */
export enum WoundCareMissingDocStatus {
  Unreviewed = 'unreviewed',
  Pending = 'pending',
  Finished = 'finished'
}

/** WoundCareOrderType */
export enum WoundCareOrderType {
  Wound = 'Wound',
  Catheter = 'Catheter',
  Ostomy = 'Ostomy',
  Tracheostomy = 'Tracheostomy',
  Unspecified = 'Unspecified'
}

/** WoundCareReadData */
export interface WoundCareReadData {
  /** Id */
  id: number
  /** Physician Order Id */
  physician_order_id: string
  /** Facility Customers Id */
  facility_customers_id: number
  /** Facility Name */
  facility_name: string
  /** Facility State */
  facility_state: string
  /** Patient Mrn */
  patient_mrn: string
  /** Mpid */
  mpid: number
  /** Patient Id */
  patient_id: string
  /** Patient Name */
  patient_name: string
  /** Patient Id Number */
  patient_id_number: string
  /** Has Medicare Hic */
  has_medicare_hic?: boolean | null
  /** Order Date */
  order_date?: string | null
  /** Order */
  order?: string | null
  order_type?: WoundCareOrderType | null
  /** Order Directions */
  order_directions?: string | null
  /** Primary Payer */
  primary_payer?: string | null
  /** Secondary Payer */
  secondary_payer?: string | null
  /** Tertiary Payer */
  tertiary_payer?: string | null
  /** @default "New" */
  order_status?: WoundCareStatus
  action?: WoundCareAction | null
  /** Updated By */
  updated_by: string
  /** Census Action Type */
  census_action_type?: string | null
  /** Currently In Building */
  currently_in_building: boolean
  /** Possible Actions */
  possible_actions?: WoundCareAction[] | null
  missing_doc_status?: WoundCareMissingDocStatus | null
}

/** WoundCareSortOption */
export enum WoundCareSortOption {
  OrderDate = 'order_date',
  ValueOrderDate = '-order_date',
  OrderStatus = 'order_status',
  ValueOrderStatus = '-order_status',
  PatientName = 'patient_name',
  ValuePatientName = '-patient_name',
  PrimaryPayer = 'primary_payer',
  ValuePrimaryPayer = '-primary_payer',
  UpdatedBy = 'updated_by',
  ValueUpdatedBy = '-updated_by'
}

/** WoundCareSourceData */
export interface WoundCareSourceData {
  /** Items */
  items: WoundCareSourceItemData[]
}

/** WoundCareSourceItemData */
export interface WoundCareSourceItemData {
  /** Physician Order Id */
  physician_order_id: string
  /** Facility Customers Id */
  facility_customers_id: string
  /** Facility Name */
  facility_name: string
  /** Facility State */
  facility_state: string
  /** Patient Mrn */
  patient_mrn: string
  /** Mpid */
  mpid: string
  /** Patient Id */
  patient_id: string
  /** Patient Name */
  patient_name: string
  /** Patient Id Number */
  patient_id_number: string
  /** Has Medicare Hic */
  has_medicare_hic?: boolean | null
  /**
   * Order Date
   * @format date-time
   */
  order_date: string
  /** Order */
  order?: string | null
  order_type?: WoundCareOrderType | null
  /** Order Directions */
  order_directions?: string | null
  /** Primary Payer */
  primary_payer?: string | null
  /** Secondary Payer */
  secondary_payer?: string | null
  /** Tertiary Payer */
  tertiary_payer?: string | null
  /** @default "Active" */
  order_status?: PCCOrderStatus
  /**
   * Last Sync At
   * @format date-time
   */
  last_sync_at: string
  /** Census Action Type */
  census_action_type?: string | null
  /** Currently In Building */
  currently_in_building: boolean
}

/** WoundCareStatisticData */
export interface WoundCareStatisticData {
  /**
   * New Orders
   * @default 0
   */
  new_orders?: number
  /**
   * Reviewed Orders
   * @default 0
   */
  reviewed_orders?: number
  /**
   * Missing Doc Orders
   * @default 0
   */
  missing_doc_orders?: number
}

/** WoundCareStatus */
export enum WoundCareStatus {
  New = 'New',
  Inactive = 'Inactive',
  Reviewed = 'Reviewed'
}

/** WoundCareUIStatus */
export enum WoundCareUIStatus {
  New = 'New',
  Inactive = 'Inactive',
  Reviewed = 'Reviewed',
  ReviewedOnCaseload = 'Reviewed_On_Caseload',
  ReviewedPayerIneligible = 'Reviewed_Payer_Ineligible',
  ReviewedOrderIneligible = 'Reviewed_Order_Ineligible',
  ReviewedMissingDoc = 'Reviewed_Missing_Doc.'
}

/** WoundCareUpdateData */
export interface WoundCareUpdateData {
  action: WoundCareAction
}

/** WoundCareUpdatedItemResponse */
export interface WoundCareUpdatedItemResponse {
  /** Id */
  id: number
  /** Physician Order Id */
  physician_order_id: string
  /** Facility Customers Id */
  facility_customers_id: number
  /** Facility Name */
  facility_name: string
  /** Facility State */
  facility_state: string
  /** Patient Mrn */
  patient_mrn: string
  /** Mpid */
  mpid: number
  /** Patient Id */
  patient_id: string
  /** Patient Name */
  patient_name: string
  /** Patient Id Number */
  patient_id_number: string
  /** Has Medicare Hic */
  has_medicare_hic?: boolean | null
  /** Order Date */
  order_date?: string | null
  /** Order */
  order?: string | null
  order_type?: WoundCareOrderType | null
  /** Order Directions */
  order_directions?: string | null
  /** Primary Payer */
  primary_payer?: string | null
  /** Secondary Payer */
  secondary_payer?: string | null
  /** Tertiary Payer */
  tertiary_payer?: string | null
  /** @default "New" */
  order_status?: WoundCareStatus
  action?: WoundCareAction | null
  /** Updated By */
  updated_by: string
  /** Census Action Type */
  census_action_type?: string | null
  /** Currently In Building */
  currently_in_building: boolean
  /** Possible Actions */
  possible_actions?: WoundCareAction[] | null
  missing_doc_status?: WoundCareMissingDocStatus | null
  /**
   * Failed
   * @default false
   */
  failed?: boolean
}

/** WoundCareUpdatedResponse */
export interface WoundCareUpdatedResponse {
  /** Items */
  items: WoundCareUpdatedItemResponse[]
  stats: WoundCareStatisticData
}

/** ActionStatus */
export interface AppIntegrationModelsActionStatus {
  status?: ActionStatusOption | null
  action?: ActionOption | null
}

/** WoundCareDocumentComponentItemRead */
export interface AppInternalWoundCareModelsWoundCareDocumentComponentItemRead {
  order_type: WoundCareOrderType
  /** Document Category */
  document_category: string
  /** Component Name */
  component_name: string
  /** Is Required */
  is_required?: boolean | null
  /**
   * Id
   * @format uuid
   */
  id: string
  /** Org Id */
  org_id?: string | null
}

/** WoundCareDocumentComponentItemUpdate */
export interface AppInternalWoundCareModelsWoundCareDocumentComponentItemUpdate {
  order_type?: WoundCareOrderType | null
  /** Document Category */
  document_category?: string | null
  /** Component Name */
  component_name?: string | null
  /** Is Required */
  is_required?: boolean | null
}

/** LabsData */
export interface AppResidentModelsLabsData {
  /** Date Info */
  date_info: string
  /** Value */
  value: string
  /**
   * Is Critical
   * @default false
   */
  is_critical?: boolean
}

/** RiskCondition */
export interface AppResidentModelsRiskCondition {
  /** Id */
  id: string
  value: RiskConditionEnum
}

/** WoundCareDocumentComponentItemRead */
export interface AppWoundCareModelsWoundCareDocumentComponentItemRead {
  /**
   * Wound Care Doc Component Id
   * @format uuid
   */
  wound_care_doc_component_id: string
  order_type: WoundCareOrderType
  /** Component Name */
  component_name: string
  /** Is Required */
  is_required: boolean
  /** Doc Obtained */
  doc_obtained?: boolean | null
}

/** WoundCareDocumentComponentItemUpdate */
export interface AppWoundCareModelsWoundCareDocumentComponentItemUpdate {
  /**
   * Wound Care Doc Component Id
   * @format uuid
   */
  wound_care_doc_component_id: string
  /** Doc Obtained */
  doc_obtained?: boolean | null
}

/** ActionStatus */
export enum SaivaInternalSdkTherapyEnumsActionStatus {
  Latest = 'latest',
  Pending = 'pending',
  Previous = 'previous'
}

/** LabsData */
export interface SaivaInternalSdkTherapyModelsLabsData {
  /** Datetime */
  datetime: string | null
  /** Description */
  description?: string | null
  /**
   * Is Critical
   * @default false
   */
  is_critical?: boolean
}

/** RiskCondition */
export interface SaivaInternalSdkTherapyModelsRiskCondition {
  /** Id */
  id: string
  value: RiskConditionEnum
  /** Datetime */
  datetime: string | null
}
