import { createContext, useContext, useEffect, useState } from 'react'
import type { AuthenticationContextType } from '../types'
import { PropsWithChildren, useMemo } from 'react'
import { useAuthenticatedUser, useUserAccessOrgs } from '../queries'
import { AuthenticationStatus } from '../types'
import { apiClient } from '../../../services/api'
import { getCurrentProduct } from '../utils'
import { SaivaProduct, UserMeRead } from 'types/api'
import { trackLastUsedApp } from '../utils/index'
import { useNavigate } from 'react-router-dom'
import { userStorage } from '../../../services/utils/local-storage'
import { LoadingPage } from '@common/loading/LoadingPage'

export const AuthenticationContext = createContext<
  AuthenticationContextType | undefined
>(undefined)

export function AuthenticationProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate()

  const {
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
    data: user
  } = useAuthenticatedUser(apiClient)

  const [product, setProduct] = useState<SaivaProduct | undefined>(undefined)

  useEffect(() => {
    const product = getCurrentProduct(user, localStorage)
    setProduct(product)
  }, [user])

  const {
    isLoading: isOrgsLoading,
    isSuccess: isOrgsSuccess,
    data: orgs
  } = useUserAccessOrgs({ apiClient, product, isLoading: isUserLoading })

  const isLoading = isUserLoading || isOrgsLoading
  const isSuccess = isUserSuccess && isOrgsSuccess

  const state = useMemo<AuthenticationContextType>(
    () => ({
      user,
      orgs,
      product: product,
      status: getStatus(isLoading, isSuccess, user),
      selectProduct: function (product: SaivaProduct) {
        trackLastUsedApp(product, localStorage)
        setProduct(product)
      },
      login: function (email, tokens) {
        userStorage.save(email, tokens)
        navigate('/')
      }
    }),
    [user, orgs, product, isLoading, isSuccess, navigate]
  )

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <AuthenticationContext.Provider value={state}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export function useAuthentication(): AuthenticationContextType {
  const context = useContext(AuthenticationContext)

  if (!context) {
    throw new Error('AuthenticationContext is missing')
  }

  return context
}

function getStatus(
  isLoading: boolean,
  isSuccess: boolean,
  user: UserMeRead | undefined
): AuthenticationStatus {
  if (isLoading) {
    return AuthenticationStatus.Loading
  }

  if (isSuccess || user) {
    return AuthenticationStatus.LoggedIn
  }

  return AuthenticationStatus.LoggedOut
}
