import { SaivaProduct, UserMeRead } from 'types/api'

export const LAST_APP_KEY = 'mysaiva-last-app'
export const APP_PATHS: Record<SaivaProduct, string> = {
  [SaivaProduct.Upt]: '/upt',
  [SaivaProduct.WoundCare]: '/wounds',
  [SaivaProduct.Therapy]: '/therapy'
}

export function selectAppPath(
  user: UserMeRead | undefined,
  storage: Storage
): string {
  if (!user) return '/login'

  const products = user.product_list

  if (products.length == 0) return '/error'

  const lastApp = storage.getItem(LAST_APP_KEY) as SaivaProduct | null

  if (!lastApp) return APP_PATHS[products[0]]
  if (!products.includes(lastApp)) return APP_PATHS[products[0]]

  return APP_PATHS[lastApp]
}

export function trackLastUsedApp(appName: string, storage: Storage) {
  storage.setItem(LAST_APP_KEY, appName)
}

export function getCurrentProduct(
  user: UserMeRead | undefined,
  storage: Storage
): SaivaProduct | undefined {
  if (!user) return undefined

  const products = user.product_list

  if (products.length == 0) return undefined

  const lastApp = storage.getItem(LAST_APP_KEY) as SaivaProduct | null

  if (!lastApp) return products[0]
  if (!products.includes(lastApp)) return products[0]

  return lastApp as SaivaProduct
}
