import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { apiClient } from '../../../services/api'
import { FcmStatusRead } from '../../../types/api'

export const statusRequestQueryKey = ['fcm_status']

const numberOfRetries =
  import.meta.env.VITE_REACT_APP_LOGIN_NUMBER_OF_RETRIES || 12
const retryInterval =
  import.meta.env.VITE_REACT_APP_LOGIN_RERTY_INTERVAL || 5000

export function useRequestStatus(searchParams: URLSearchParams) {
  const [, setSearchParams] = useSearchParams()
  const params = useMemo(
    function getParams(): URLSearchParams {
      const params = new URLSearchParams(searchParams)

      return params
    },
    [searchParams]
  )

  async function getStatus() {
    const res = await apiClient.get<FcmStatusRead>(
      `v2/auth/fcm_status?${params.toString()}`
    )
    return res.data
  }

  function expireStatus(
    count: number,
    query: FcmStatusRead | undefined
  ): number | false {
    if (query?.status === 'Pending') {
      if (count > numberOfRetries) {
        setSearchParams((params) => {
          const p = new URLSearchParams(params)
          p.append('expired', 'true')
          return p
        })

        return false
      }

      return retryInterval
    } else {
      return false
    }
  }

  return useQuery({
    queryFn: getStatus,
    queryKey: statusRequestQueryKey,
    refetchInterval: (query) =>
      expireStatus(query.state.dataUpdateCount, query.state.data)
  })
}
